import React, { useState } from "react";
import Layout from "../../Components/Layout";
import axios from "axios";
function Donate() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    amount: "",
  });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const alphabetRegex = /^[A-Za-z\s]+$/;
    if (!formData.amount) {
      newErrors.amount = "Amount is required";
    } else if (isNaN(formData.amount) || formData.amount <= 0) {
      newErrors.amount = "Amount should be a Positive number";
    }
    if (!formData.name) {
      newErrors.name = "Name is required.";
    } else if (!alphabetRegex.test(formData.name)) {
      newErrors.name = "Name can only contain alphabets.";
    }
    if (!formData.name) {
      newErrors.name = "Name is required.";
    }
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please enter a valid email.";
    }
    if (!formData.phone || !/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Please enter a valid 10-digit phone number.";
    }
    if (!formData.subject) {
      newErrors.subject = "Subject is required.";
    }
    if (!formData.message) {
      newErrors.message = "Message is required.";
    }
    return newErrors;
  };

  const amount = formData.amount * 100;
  const currency = "INR";
  const receiptId = "qwsaq1";
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const paymentHandler = async (e) => {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/donate`,
      {
        amount,
        currency,
        receipt: receiptId,
      }
    );
    const order = await response;
    console.log(order);

    var options = {
      key: "rzp_test_4YoEwAlTSaCB5k",
      amount,
      currency,
      name: "SSN",
      description: "Test Transaction",
      image: "https://example.com/your_logo",
      order_id: order.id,
      handler: async function (response) {
        const body = {
          ...response,
        };

        // const validateRes = await axios.post(
        //   `${process.env.REACT_APP_BASE_URL}donate/validate`,
        //   body
        // );
        // const jsonRes = await validateRes;
        // console.log(jsonRes);
      },
      prefill: {
        //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
        name: formData.name, //your customer's name
        email: formData.email,
        contact: formData.phone, //Provide the customer's phone number for better conversion rates
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });
    rzp1.open();
    setFormData("");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      // Submit the form
      console.log("Form submitted", formData);
      paymentHandler();
    }
  };

  const handleAmountClick = (amount) => {
    setFormData({
      ...formData,
      amount,
    });
  };
  return (
    <Layout
      title="Donate-Now"
      discription={"Donate in Sanatan sewanyas"}
      keywords={"Donation Sanatan Sewanyas"}
    >
      <section className="page-header">
        <div className="page-header__bg"></div>
        <div className="container">
          <ul className="sanatanSewaNyas-breadcrumb list-unstyled">
            <li>
              <a href="index.html">Home</a>
            </li>
            <li>
              <span>Donation</span>
            </li>
          </ul>
          <h2 className="page-header__title">
            {/* Donate Now */}दान करें
            </h2>
        </div>
      </section>
      <section className="donate-page">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <form onSubmit={handleSubmit} className="donate-page__form">
                <div className="donate-page">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-lg-12">
                        <div className="donate-page__form__amount">
                          <div className="donate-page__form__amount__box">
                            <span className="donate-page__form__amount__sign">
                              {" "}
                              ₹
                            </span>
                            <input
                              type="text"
                              value={formData.amount}
                              name="amount"
                              id="donate_amount"
                              // placeholder="Enter amount"
                              placeholder="राशि दर्ज करें"
                              className="donate-page__form__amount__input"
                              onChange={handleChange}
                              autoComplete="off"
                            />
                          </div>
                          {errors.amount && (
                            <div className="error">{errors.amount}</div>
                          )}
                          <div className="donate-page__form__amount__buttons">
                            {[100, 200, 500, 1000].map((amount) => (
                              <button
                                key={amount}
                                type="button"
                                className="donate-page__form__amount__btn"
                                onClick={() => handleAmountClick(` ${amount}`)}
                              >
                                ₹{amount}
                              </button>
                            ))}
                            <button
                              type="button"
                              className="donate-page__form__amount__btn"
                              onClick={() => handleAmountClick("")}
                            >
                              {/* Custom amount */}विशेष राशि
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <h3 className="donate-page__form__info-title">
                  {/* Personal Info */}व्यक्तिगत जानकारी
                  </h3>
                <div className="form-one">
                  <div className="form-one__group">
                    <div className="form-one__control">
                      <input
                        type="text"
                        name="name"
                        // placeholder="Your name"
                        placeholder="आपका नाम"
                        value={formData.name}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      {errors.name && (
                        <div className="error">{errors.name}</div>
                      )}
                    </div>
                    <div className="form-one__control">
                      <input
                        type="email"
                        name="email"
                        // placeholder="Email address"
                        placeholder="ईमेल"
                        value={formData.email}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      {errors.email && (
                        <div className="error">{errors.email}</div>
                      )}
                    </div>
                    <div className="form-one__control">
                      <input
                        type="text"
                        name="phone"
                        // placeholder="Phone"
                        placeholder="फोन"
                        value={formData.phone}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      {errors.phone && (
                        <div className="error">{errors.phone}</div>
                      )}
                    </div>
                    <div className="form-one__control">
                      <input
                        type="text"
                        name="subject"
                        // placeholder="Subject"
                        placeholder="विषय"
                        value={formData.subject}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      {errors.subject && (
                        <div className="error">{errors.subject}</div>
                      )}
                    </div>
                    <div className="form-one__control form-one__control--full">
                      <textarea
                        name="message"
                        // placeholder="Write a message"
                        placeholder="एक संदेश लिखें"
                        value={formData.message}
                        onChange={handleChange}
                        autoComplete="off"
                      ></textarea>
                      {errors.message && (
                        <div className="error">{errors.message}</div>
                      )}
                    </div>
                    <div className="form-one__control form-one__control--full">
                      <button
                        onClick={handleSubmit}
                        className="sanatanSewaNyas-btn sanatanSewaNyas-btn--icon"
                      >
                        <span>
                          {/* Donate now */}दान करें
                          </span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Donate;
