import {
  faFacebook,
  faInstagram,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <div className="page-wrapper">
        <footer className="main-footer p-1">
          <div className="container">
            <div className="mt-5">
              <div className="row justify-content-around">
                <div className="col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                  <div className="footer-widget footer-widget--about">
                    <Link to={"/"} className="footer-widget__logo">
                      <img
                        src="/assets/Images/logo.png"
                        alt="sanatan sewa nyas"
                      />
                    </Link>
                    <div className="footer-widget__social">
                      <Link
                        to={"https://www.facebook.com/abhymspeaks"}
                        target="_blank"
                      >
                        <FontAwesomeIcon
                          icon={faFacebook}
                          size="xl"
                          style={{ color: "#ffffff" }}
                        />
                      </Link>
                      <Link
                        to={
                          "https://www.instagram.com/theshivommishra?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                        }
                        target="_blank"
                      >
                        <FontAwesomeIcon
                          icon={faInstagram}
                          size="xl"
                          style={{ color: "#ffffff" }}
                        />
                      </Link>
                      <Link
                        to={"https://www.youtube.com/@SanatanSewaNyas"}
                        target="_blank"
                      >
                        <FontAwesomeIcon
                          icon={faYoutube}
                          size="xl"
                          style={{ color: "#ffffff" }}
                        />
                      </Link>
                      <Link to={"https://x.com/ishivommishra"} target="_blank">
                        <FontAwesomeIcon
                          icon={faXTwitter}
                          size="xl"
                          style={{ color: "#ffffff" }}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-2 col-xl-2 col-xxl-2">
                  <div className="footer-widget footer-widget--links">
                    <h2 className="footer-widget__title">Usefull Links</h2>
                    <ul className="list-unstyled footer-widget__links">
                      <li>
                        <Link to={"/"}>Home</Link>
                      </li>
                      <li>
                        <Link to={"/about"}>About us</Link>
                      </li>

                      <li>
                        <Link to={"/contact"}>Contact us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 col-lg-2 col-xl-2 col-xxl-2">
                  <div className="footer-widget footer-widget--links">
                    <h2 className="footer-widget__title">Links</h2>
                    <ul className="list-unstyled footer-widget__links">
                      <li>
                        <Link to={"/temples"}>Temples</Link>
                      </li>
                      <li>
                        <Link to={"/events"}>Events</Link>
                      </li>
                      <li>
                        <Link to={"/events"}>Latest Events</Link>
                      </li>
                      <li>
                        <Link to={"/donate"}>Donate Now</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                  {/* <div className="footer-widget footer-widget--mail">
                  <h2 className="footer-widget__title">Contact Us</h2>
                  <ul>
                    <li>
                      <a>Location</a>
                    </li>
                  </ul>
                  <a></a>
                  <a>-0987657890-09876</a>
                  <p>info@sanatansewanyas.com</p>
                 
                </div> */}
                  <div className="footer-widget footer-widget--links">
                    <h2 className="footer-widget__title">Contact</h2>
                    <ul className="list-unstyled footer-widget__links">
                      <li>
                        <h3>Location</h3>
                        <Link
                          to={"https://maps.app.goo.gl/hhfxQHycfJrYXFZG9"}
                          target="_blank"
                        >
                          6QJ7+WCJ, Okha, Surat, Gujarat 395005,+91 4567890987654
                        </Link>
                      </li>
                      {/* <li>
                        <Link to={"#"}>+91 4567890987654</Link>
                      </li> */}
                      {/* <li>
                        <form
                          action="#"
                          data-url="MAILCHIMP_FORM_URL"
                          className="footer-widget__newsletter mc-form"
                        >
                          <input
                            type="text"
                            name="EMAIL"
                            placeholder="Email address"
                          />
                          <button type="submit" className="fas fa-paper-plane">
                            <FontAwesomeIcon
                              icon={faPaperPlane}
                              size="lg"
                              style={{ color: "#ffffff" }}
                            />
                          </button>
                        </form>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className="main-footer-bottom">
          <div className="container">
            <p className="main-footer-bottom__copyright">
              Copyright Sanatan Sewanyas @2024. All rights reserved
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
