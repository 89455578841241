import { faMagnifyingGlass, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { faTimes, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faYoutube,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import NewspaperIcon from '@mui/icons-material/Newspaper';
import GroupsIcon from '@mui/icons-material/Groups';
import CollectionsIcon from '@mui/icons-material/Collections';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
const MainHeader = () => {
  const [isMobile, setIsmobile] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const handleResultClick = (id) => {
    navigate(`/temple-detail/${id}`);
    window.location.reload();
    setIsSearch(false);
  };
  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/search-temples?title=${searchQuery}`
        );
        setSearchResults(response.data);
        setLoading(false);
      } catch (error) {
        setError("Failed to fetch search results");
        setLoading(false);
      }
    };

    if (searchQuery) {
      fetchSearchResults();
    } else {
      setSearchResults([]);
    }
  }, [searchQuery]);

  const handleInputChange = (e) => {
    console.log();
    setSearchQuery(e.target.value);
  };


  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <header className="main-header main-header--two sticky-header sticky-header--normal">
        <div className="container-fluid">
          <div className="main-header__top">
            <i className="main-header__top__icon"></i>
            <span style={{color:"#FE8001"}}>: वसुधैव कुटुम्बकम् :</span>
          </div>
          <div className="main-header__logo">
            <Link to={"/"}>
              <img src="/assets/Images/logo.png" alt="Sanatann sewanyas" />
            </Link>
          </div>
          <div className="main-header__inner">
            <nav className="main-header__nav main-menu">
              <ul className="main-menu__list">
                <li>
                  <Link to={"/"}>Home</Link>
                </li>
                <li>
                  <Link to={"/about"}>About</Link>
                </li>
                {/* <li>
                  <Link to="/donate">Donate now</Link>
                </li> */}
                <li>
                  <Link to="/events">Events</Link>
                </li>

                <li>
                  <Link to="/temples">Temples</Link>
                </li>
                <li>
                  <Link to={"/contact"}>Contact us</Link>
                </li>
                <li>
                <Link onClick={toggleDropdown}> <div>Media</div><div><KeyboardArrowDownIcon /></div></Link>
                  
                  {isOpen && (
                    <ul style={{
                      position: 'absolute',
                      top: '100%',
                      left: '0',
                      backgroundColor: 'white',
                      border: '1px solid #ccc',
                      listStyle: 'none',
                      padding: '0',
                      margin: '0'
                    }}>
                      <li className="">
                        <Link 
                        to={"/press-release"}
                         >
                            <div className=""><NewspaperIcon /></div>
                            <div className="ms-2">Press Release</div>
                        </Link>
                      </li>
                      <li>
                        <Link  to={"/imagegallery"} >
                        <div><CollectionsIcon/></div>
                        <div className="ms-2">Image Gallery</div>
                        </Link>
                      </li>
                      <li><Link to={"/videogallery"} >
                      <div>< VideoCameraBackIcon/>
                    </div><div className="ms-2">Video Gallery</div></Link></li>
                    </ul>
                  )}
                </li>
                <li>
                  <Link 
                  to="/ourteam"
                  >Our Team</Link>
                </li>
                <li>
                  <Link 
                  to={"/joinus"}
                   >Join us</Link>
                </li>
                <li>
                  <Link to="/donate">Donate now</Link>
                </li>
              </ul>
            </nav>
            <div className="main-header__right">
              <div
                className="mobile-nav__btn mobile-nav__toggler"
                onClick={() => setIsmobile(!isMobile)}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
              <Link
                href="#"
                className="search-toggler main-header__search"
                onClick={() => {
                  setIsSearch(!isSearch);
                }}
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} />{" "}
              </Link>
            </div>
          </div>
        </div>
      </header>
      <div className={`mobile-nav__wrapper ${isMobile ? "expanded" : ""}`}>
        <div
          className="mobile-nav__overlay mobile-nav__toggler"
          onClick={() => setIsmobile(!isMobile)}
        ></div>
        <div className="mobile-nav__content">
          <span
            className="mobile-nav__close mobile-nav__toggler"
            onClick={() => setIsmobile(!isMobile)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </span>
          <div className="logo-box" style={{ width: "100px" }}>
            <Link to="/">
              <img src="/assets/Images/logo.png" alt="sanatan sewanyas" />
            </Link>
          </div>
          <div className="mobile-nav__container"></div>
          <ul className="mobile-nav__contact list-unstyled">
            <li>
              <FontAwesomeIcon icon={faEnvelope} />
              <Link
                href="mailto:needhelp@aofixo.com"
                style={{ marginLeft: "10px" }}
              >
                needhelp@aofixo.com
              </Link>
            </li>
            <li>
              <FontAwesomeIcon icon={faPhone} style={{ color: "#ffffff" }} />
              <Link href="tel:666-888-0000" style={{ marginLeft: "10px" }}>
                {" "}
                {"    "}+91 666 888 0000
              </Link>
            </li>
          </ul>
          <nav className="main-header__nav main-menu">
            <ul className="mobile-nav__contact list-unstyled">
              <li>
                <Link to={"/"}>Home</Link>
              </li>
              <li>
                <Link to={"/about"}>About</Link>
              </li>
              <li>
                <Link to="/donate">Donate now</Link>
              </li>
              <li>
                <Link to="/events">Events</Link>
              </li>

              <li>
                <Link to="/temples">Temples</Link>
              </li>
             
              <li>
                <Link to={"/contact"}>Contact us</Link>
              </li>
            </ul>
          </nav>
          <div className="mobile-nav__social">
            <Link target="_blank" to={"https://www.facebook.com/abhymspeaks"}>
              <FontAwesomeIcon
                icon={faFacebook}
                size="xl"
                style={{ color: "#ffffff" }}
              />
            </Link>
            <Link
              target="_blank"
              to={
                "https://www.instagram.com/theshivommishra?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
              }
            >
              <FontAwesomeIcon
                icon={faInstagram}
                size="xl"
                style={{ color: "#ffffff" }}
              />
            </Link>
            <Link
              target="_blank"
              to={"https://www.youtube.com/@SanatanSewaNyas"}
            >
              <FontAwesomeIcon
                icon={faYoutube}
                size="xl"
                style={{ color: "#ffffff" }}
              />
            </Link>
            <Link target="_blank" to={"https://x.com/ishivommishra"}>
              <FontAwesomeIcon
                icon={faXTwitter}
                size="xl"
                style={{ color: "#ffffff" }}
              />
            </Link>
          </div>
        </div>
      </div>
      <div className={`search-popup ${isSearch ? "active" : ""}`}>
        <div
          className="search-popup__overlay search-toggler"
          onClick={() => {
            setIsSearch(!isSearch);
          }}
        ></div>
        <div className="search-popup__content">
          <form
            role="search"
            method="get"
            className="search-popup__form"
            action="#"
          >
            <input
              type="text"
              id="search"
              placeholder="Search Here..."
              value={searchQuery}
              onChange={handleInputChange}
            />
            <button
              type="submit"
              aria-label="search submit"
              className="aofixo-btn aofixo-btn--base"
            >
              <span>
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </button>
          </form>
          {loading && <div>Loading...</div>}
          {error && <div>{error}</div>}
          {searchResults.length > 0 && (
            <ul className="mobile-nav__contact list-unstyled">
              {searchResults.map((result) => {
                return (
                  <li key={result._id}>
                    <div onClick={() => handleResultClick(result._id)}>
                      {result.title}
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </>
  );
};

const HeaderTwo = () => {
  return (
    <div>
      <MainHeader />
    </div>
  );
};

export default HeaderTwo;
