import React from "react";
import Slider from "react-slick";
import img1 from "../../Assets/Images/homeBanner/ayodhya.png";
import img2 from "../../Assets/Images/homeBanner/indian-mandir.png";
import img3 from "../../Assets/Images/homeBanner/kedarnath.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';
import { useState,useEffect } from "react";

const HomeBanner = () => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    fade: true,
    cssEase: "linear",
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    // adaptiveHeight: true,
    prevArrow: false,
    nextArrow: false,
    arrows: false,
  };

  const slides = [
    {
      backgroundImage: img1,
      subTitle: "Start donating them now",
      title: "education contributes to a better life",
      buttonText: "Discover more",
      buttonLink: "about.html",
      text: "Let’s you can be a part of changes in the world",
    },
    {
      backgroundImage: img2,
      subTitle: "Start donating them now",
      title: "education contributes to a better life 22222",
      buttonText: "Discover more",
      buttonLink: "about.html",
      text: "Let’s you can be a part of changes in the world 33333",
    },
    {
      backgroundImage: img3,
      subTitle: "Start donating them now",
      title: "education contributes to a better life 444444",
      buttonText: "Discover more",
      buttonLink: "about.html",
      text: "Let’s you can be a part of changes in the world",
    },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/get-all-temples`);
        setData(response.data);
        console.log("slidett",data)
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
   console.log(``)
  return (
    <div className="main-slider-two">
      <Slider {...sliderSettings} className="main-slider-two__carousel">
        {data.data?.map((slide, index) => (
          <div className="item" key={index}>
            <div className="main-slider-two__item">
              <div className="main-slider-two__bg ">
                <img src={`${process.env.REACT_APP_BASE_URL}/temples/${slide?.bannerImage?.image}`} alt="" />
              </div>

              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="slider-content overlay ">
                      {/* <h2 className="slider-content__sub-title">
                        {slide.title }
                      </h2> */}
                      <h2
                        className="slider-content__title text-center "
                        dangerouslySetInnerHTML={{ __html: slide.title }}
                        style={{color:"white",fontWeight:600}}
                      />
                      <div className="slider-content__btn">
                        {/* <a href={slide.link} className="sanatanSewaNyas-btn">
                          <span>Discover more</span>
                        </a> */}
                        <p
                          className="slider-content__text  text-center "
                          style={{color:"white",fontWeight:600}}
                          dangerouslySetInnerHTML={{ __html: slide.shortdescription
                          }}
                        />
                      </div>
                      <></>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default HomeBanner;
