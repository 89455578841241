import React, { useState } from 'react'
import Layout from "../../Components/Layout";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import section22 from "../../Assets/Images/homeBanner/ram-mandir.png";
import axios from 'axios';
function Joinus() {
  const [formData, setFormData] = useState({
    name: '',
    fathername: '',
    mothername: '',
    email: '',
    phone: '',
    adhare: '',
    address: '',
    city:'',
    state: '',
    country: '',
    purpose: ''
  });

  // State for file inputs
  const [photoFile, setPhotoFile] = useState(null);
  const [adhareFrontFile, setAdhareFrontFile] = useState(null);
  const [adhareBackFile, setAdhareBackFile] = useState(null);

    // Update text inputs state
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormData({ ...formData, [name]: value });
    };
  
    // Update file inputs state
    const handleFileChange = (event, fileType) => {
      const file = event.target.files[0];
      switch (fileType) {
        case 'photo':
          setPhotoFile(file);
          break;
        case 'Adharefront':
          setAdhareFrontFile(file);
          break;
        case 'Adhareback':
          setAdhareBackFile(file);
          break;
        default:
          break;
      }
    };

    const handleFormSubmit = async (event) => {
      event.preventDefault(); // Prevent default form submission
      try {
        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('fathername', formData.fathername);
        formDataToSend.append('mothername', formData.mothername);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('phone', formData.phone);
        formDataToSend.append('adhare', formData.adhare);
        formDataToSend.append('address', formData.address);
        formDataToSend.append('state', formData.state);
        formDataToSend.append('city', formData.city);
        formDataToSend.append('country', formData.country);
        formDataToSend.append('purpose', formData.purpose);
        if (photoFile) formDataToSend.append('photo', photoFile);
        if (adhareFrontFile) formDataToSend.append('Adharefront', adhareFrontFile);
        if (adhareBackFile) formDataToSend.append('Adhareback', adhareBackFile);
  

        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/add-committee-members`, formDataToSend,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
  
        console.log('Data uploaded successfully:', response);
        // Optionally handle success, show message, update UI, etc.
      } catch (error) {
        console.error('Error uploading data:', error);
        // Handle error: show message, update UI, etc.
      }
    };
    
  return (
    <Layout>        
        <section className="page-header">
          <div
            className="page-header__bg"
            // style={{ backgroundImage: `url(${bannerImage})` }}
            // style={{ backgroundImage: `url(${templeBannerImage})` }}
          ></div>
          <div className="container">
            <ul className="sanatanSewaNyas-breadcrumb list-unstyled">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <span>Join us</span>
              </li>
            </ul>
            <h2 className="page-header__title">
              {/* Temples Page */}हमसे जुड़ें
              </h2>
          </div>
        </section>
       
      <section className="contact-one">
        <div className="container">
          <div
            className="contact-one__inner"
            style={{
              backgroundImage: "url(assets/images/shapes/contact-bg.jpg)",
            }}
          >
            <div className="contact-one__inner__shape"></div>
            <div className="row">
              <div className="col-xl-12 d-flex flex-column align-items-center">
                <div className="sec-title text-center">
                  <h6 className="sec-title__tagline">
                    {/* Contact with us */}हमसे संपर्क करें
                    </h6>
                  <h3 className="sec-title__title">
                    {/* Feel free to write anytime */}
                    हमसे संपर्क करने में संकोच न करें।
                  </h3>
                </div>
                <form
                  className="contact-one__form contact-form-validated wow fadeInUp"
                  data-wow-duration="1500ms"
                  onSubmit={handleFormSubmit}
                >
                  <div className="form-one__group">
                    <div className="form-one__control">
                      <input
                        type="text"
                        name="name"
                        placeholder="आपका नाम"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                      {/* {errors.name && <p className="error">{errors.name}</p>} */}
                    </div>
                    <div className="form-one__control">
                      <input
                        type="text"
                        name="fathername"
                        value={formData.fathername}
                        placeholder="पिता का नाम"
                        // value={formData.name}
                        onChange={handleInputChange}
                      />
                      {/* {errors.name && <p className="error">{errors.name}</p>} */}
                    </div>
                    <div className="form-one__control">
                      <input
                        type="text"
                        name="mothername"
                        placeholder="माता का नाम"
                        value={formData.mothername} 
                        onChange={handleInputChange}
                      />
                      {/* {errors.name && <p className="error">{errors.name}</p>} */}
                    </div>
                    <div className="form-one__control">
                      <input
                        type="email"
                        name="email"
                        placeholder="ईमेल पता"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                      {/* {errors.email && <p className="error">{errors.email}</p>} */}
                    </div>
                    <div className="form-one__control">
                      <input
                        type="text"
                        name="phone"
                    
                        placeholder="फोन नंबर"
                        value={formData.phone}
                        onChange={handleInputChange}
                      />
                      {/* {errors.phone && <p className="error">{errors.phone}</p>} */}
                    </div>
                    <div className="form-one__control">
                      <input
                        type="text"
                       name="adhare"
                        value={formData.adhare}
                        placeholder="आधार संख्या"
                        onChange={handleInputChange}
                      />
                      {/* {errors.phone && <p className="error">{errors.phone}</p>} */}
                    </div>
                    <div className="form-one__control">
                      <input
                        type="text"
                        name="address"
                        value={formData.address}
                          placeholder="पता"
                        onChange={handleInputChange}
                      
                     
                      />
                      {/* {errors.subject && (
                        <p className="error">{errors.subject}</p>
                      )} */}
                    </div>
                    <div className="form-one__control">
                      <input
                        type="text"
                        name="city"
                        value={formData.city}
                        placeholder="शहर"
                        // value={formData.subject}
                        onChange={handleInputChange}
                      />
                      {/* {errors.subject && (
                        <p className="error">{errors.subject}</p>
                      )} */}
                    </div>
              
                    <div className="form-one__control">
                      <input
                        type="text"
                        name="state" 
                        placeholder="राज्य"
                        value={formData.state}
                        onChange={handleInputChange}
                      />
                      {/* {errors.subject && (
                        <p className="error">{errors.subject}</p>
                      )} */}
                    </div>
                    <div className="form-one__control">
                      <input
                        type="text"
                        name="country"
                     value={formData.country}
                        placeholder="देश"
                    
                        onChange={handleInputChange}
                      />
                      {/* {errors.subject && (
                        <p className="error">{errors.subject}</p>
                      )} */}
                    </div>
                    <div className="form-one__control">
                      <input
                        type="text"
                       name="purpose"
                       
                        placeholder="जुड़ने का उद्देश्य "
                        value={formData.purpose} 
                        onChange={handleInputChange}
                      />
                      {/* {errors.subject && (
                        <p className="error">{errors.subject}</p>
                      )} */}
                    </div>
                    <div className="form-one__control form-one__control--full">
                    <label>Choose Photo:</label>
                     <input
                        type="file"
                        name="purpose"
                       
                        placeholder="जुड़ने का उद्देश्य "
                        onChange={(e) => handleFileChange(e, 'photo')} 
                      />
                      {/* {errors.message && (
                        <p className="error">{errors.message}</p>
                      )} */}
                    </div>
                    <div className="form-one__control form-one__control--full">
                    <label>Choose Adhare Front:</label>
                     <input
                        type="file"
                        name="purpose"
                       
                        placeholder="जुड़ने का उद्देश्य "
                        onChange={(e) => handleFileChange(e, 'Adharefront')}
                      />
                      {/* {errors.message && (
                        <p className="error">{errors.message}</p>
                      )} */}
                    </div>
                    <div className="form-one__control form-one__control--full">
                    <label>Choose Adhare Back:</label>
                     <input
                        type="file"
                        name="purpose"
                       
                        placeholder="जुड़ने का उद्देश्य "
                        onChange={(e) => handleFileChange(e, 'Adhareback')}
                      />
                      {/* {errors.message && (
                        <p className="error">{errors.message}</p>
                      )} */}
                    </div>
                    <div className="form-one__control form-one__control--full">
                      <button type="submit" className="sanatanSewaNyas-btn">
                        <span>
                          {/* Send Message */}संदेश भेजें
                          </span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
  </Layout>
  )
}

export default Joinus