import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import Slider from "react-slick";
import HomeBanner from "../../Components/HomeBanner";
import section21 from "../../Assets/Images/homeBanner/amarnathh.jpeg";
import section22 from "../../Assets/Images/homeBanner/ram-mandir.png";
import about1 from "../../Assets/Images/Shapes/ram.jpeg";
import arrow1 from "../../Assets/Images/Shapes/video-btn-arrow.png";
import logo from "../../Assets/Images/Logo/snatan-seva-logo.png";

import {
  faArrowRightLong,
  faHandsPraying,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import Layout from "../../Components/Layout";
import axios from "axios";
import { Link } from "react-router-dom";

function Home() {
  const [homeData, setHomeData] = useState();
  const [eventSlide, setEventSlide] = useState([]);
  const [tempHeight, settempHeight] = useState();
  const [allEvent, setAllEvent] = useState([]);
  const [user, setuser] = useState("");
  const [id, setid] = useState("");
  const [famous, setFamous] = useState([]);
  const slideRef = useRef();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    amount: "",
  });

  const info = "End";
  const amount = formData.amount * 100;
  const currency = "INR";
  const receiptId = "qwsaq1";

  const paymentHandler = async (e) => {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/donate`,
      {
        amount,
        currency,
        receipt: receiptId,
      }
    );
    const order = await response;
    console.log(order);

    var options = {
      key: "rzp_test_4YoEwAlTSaCB5k",
      amount,
      currency,
      name: "SSN",
      description: "Test Transaction",
      image: "https://example.com/your_logo",
      order_id: order.id,
      handler: async function (response) {
        const body = {
          ...response,
        };

        const validateRes = await axios.post(
          `${process.env.REACT_APP_BASE_URL}donate/validate`,
          body
        );
        const jsonRes = await validateRes;
        console.log(jsonRes);
      },
      prefill: {
        //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
        name: formData.name, //your customer's name
        email: formData.email,
        contact: formData.phone, //Provide the customer's phone number for better conversion rates
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });
    rzp1.open();
  };

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }

    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }

    if (!formData.phone) {
      newErrors.phone = "Phone is required";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Phone is invalid";
    }

    if (!formData.amount) {
      newErrors.amount = "Amount is required";
    } else if (isNaN(formData.amount) || formData.amount <= 0) {
      newErrors.amount = "Amount should be a Positive number";
    }

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      // Submit the form
      console.log("Form submitted", formData);
      paymentHandler();
    }
  };
  const getHomeData = async () => {
    try {
      // Fetch temporary slide data
      const tempResponse = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/get-temp-slide`
      );
      console.log("temRespose",tempResponse.data.data)
      if (tempResponse.status === 200) {
        // Assuming setHomeData is a state updater function from React useState hook
        setHomeData((prev) => ({
          ...prev,
          tempSlide: tempResponse.data.data,
        }));
        const famousResponse = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/get-famous-slide`
        );
        if (famousResponse.status === 200) {
          setFamous(famousResponse.data?.data);
          console.log(famous);
        }
        // Fetch all events data
        const eventResponse = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/get-upcoming-events`
        );
        if (eventResponse.data?.status === 200) {
          // Assuming setEventSlide is a state updater function from React useState hook
          setEventSlide(eventResponse.data?.data);
        }

        const allEventResponse = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/get-previous-events`
        );
        if (allEventResponse.data?.status === 200) {
          setAllEvent(allEventResponse.data?.data);
          // Extract unique organizer IDs
          const organizerIds = [
            ...new Set(allEvent.map((event) => event.organizer)),
          ];

          // Fetch user data for each organizer
          const userResponses = await Promise.all(
            organizerIds.map((id) =>
              axios.get(`${process.env.REACT_APP_BASE_URL}/user-by-id/${id}`)
            )
          );

          // Debug: Log the responses to see their structure

          const usersData = {};

          for (const userId in userResponses) {
            const response = userResponses[userId];

            if (response.status === 200) {
              const userData = response.data;

              if (userData && userData._id && userData.name) {
                usersData[userData._id] = userData.name;
              }
            }
          }

          // Debug: Log the usersData to check if it has been populated correctly

          setuser(usersData);
        }
      } else {
        console.log("Failed to fetch temp slide data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle errors more gracefully, e.g., show an error message to the user
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const templesSlider = {
    slidesToShow: 3,
    autoplay: false,
    infinite: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    getHomeData();
  }, []);
  useEffect(() => {
    const temSlideHeight = slideRef.current;
    settempHeight(temSlideHeight?.clientHeight);
  }, [homeData?.tempSlide]);

  // Helper function to truncate text to 145 words
  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length <= wordLimit) {
      return text;
    }
    return words.slice(0, wordLimit).join(" ") + "...";
  };

  function truncateToTen(str) {
    if (str.length > 10) {
      return str.slice(0, 10) + "...";
    } else {
      return str;
    }
  }

  return (
    <Layout>
      <HomeBanner />
      {homeData?.tempSlide && (
        <section
          className="position-relative d-flex justify-content-center"
          style={{ height: tempHeight }}
        >
          <div className="tempSlideContainer w-100" ref={slideRef}>
            <h1 className="text-center font-weight-bold title-card-heading">
              प्रसिद्ध मंदिर
            </h1>
            <div className="container">
              <Slider {...templesSlider}>
                {homeData?.tempSlide.map((item, i) => {
                const state=  truncateToTen(item.state)
                  return (
                    <div key={i} className="rounded tempSlideCard pb-4 mt-4" >
                      
                      <div className="shadow box  d-flex flex-column align-items-center justify-content-between feature-three__item__inner">
                        <div className="miniImageCard rounded-circle ">
                          <img
                            src={`${process.env.REACT_APP_BASE_URL}/temples/${item?.mainImage?.image}`}
                            className="w-100 h-100 rounded-circle"
                            alt={item.title}
                          />
                          {/* <img
                            src={about1}
                            className="w-100 h-100 rounded-circle"
                            alt="TEMPLE"
                          /> */}
                        </div>
                        <div className="card-detail  text-center ">
                          <h4 className="feature-three__item__title">
                            {item.title}
                          </h4>
                          <p  style={{fontSize:"0.8rem"}}>{item.city.length>=15? `${item.city}`:`${item.city},${state}`}</p>
                          <p className="feature-three__item__text">
                            {item.shortdescription.substr(0, 125)}
                          </p>
                        </div>
                        <Link
                          to={`/temple-detail/${item._id}`}
                          className="feature-three__item__rm"
                        >
                          <FontAwesomeIcon
                            className="fs-2"
                            icon={faArrowRightLong}
                            color="black"
                          />
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </section>
      )}

      {/* About us  */}
      <section className="about-two mb-120">
        <div className="container">
          <div className="row justify-content-between">
            <div
              className="col-xl-4 col-lg-5 col-md-6 col-sm-10   wow fadeInLeft"
              data-wow-delay="300ms"
            >
              <div className="about-two__image">
                <img src={about1} alt="sanatanSewaNyas" />
              </div>
            </div>
            <div
              className="col-xl-6  col-lg-7 col-md-6 col-sm-12  wow fadeInRight"
              data-wow-delay="300ms"
            >
              <div className="about-two__content">
                <div className="sec-title text-left">
                  <h6 className="sec-title__tagline">हमारे बारे मे।</h6>
                  <h3 className="sec-title__title">
                    निःस्वार्थ सेवा और सनातन ज्ञान से सशक्तिकरण।
                  </h3>
                </div>
                <p className="about-two__content__text">
                  सनातन सेवा न्याय, जिसको वर्ष 2020 में स्थापित किया गया, एक
                  एनजीओ है जो सेवा, करुणा और न्याय के सनातन सिद्धांतों पर आधारित
                  है। <br></br>हमारी टीम, कर्तव्य और सेवा की गहरी भावना से
                  प्रेरित होकर, सामाजिक कल्याण, शैक्षिक सशक्तिकरण और न्याय एवं
                  उत्तरदायित्व की वकालत के माध्यम से निचले वर्गों को उठाने के
                  लिए अथक प्रयास करती है।<br></br> सनातन सेवा न्याय में, हम एक
                  ऐसे समाज की कल्पना करते हैं जहां प्रत्येक व्यक्ति को प्रगति
                  करने और अपनी पूरी क्षमता तक पहुंचने का अवसर मिलता हो।
                </p>
                <div className="row">
                  <div className="col-md-12">
                    <ul className="about-two__content__list">
                      <li>
                        <span>
                          <FontAwesomeIcon
                            icon={faHeart}
                            beat
                            style={{ color: "#FFD43B" }}
                          />
                        </span>
                        सनातन दर्शन और जीवन शैली को बढ़ावा देना।
                      </li>
                      <li>
                        <span>
                          <FontAwesomeIcon
                            icon={faHeart}
                            beat
                            style={{ color: "#FFD43B" }}
                          />
                        </span>
                        निःस्वार्थ सेवा को बढ़ावा देना।
                      </li>
                      <li>
                        <span>
                          <FontAwesomeIcon
                            icon={faHeart}
                            beat
                            style={{ color: "#FFD43B" }}
                          />
                        </span>
                        न्याय और उत्तरदायित्व को बढ़ावा देना।
                      </li>
                      <li>
                        <span>
                          <FontAwesomeIcon
                            icon={faHeart}
                            beat
                            style={{ color: "#FFD43B" }}
                          />
                        </span>
                        सनातन धर्म के शाश्वत ज्ञान और बुद्धिमत्ता को बढ़ावा
                        देना।
                      </li>
                    </ul>
                  </div>
                </div>

                <Link to={"/about"} className="sanatanSewaNyas-btn">
                  <span>अधिक जानें</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="donation-one donation-one--home-two">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="sec-title text-center">
                <h6 className="sec-title__tagline">प्रसिद्ध सनातन मंदिर।</h6>
                <h3 className="sec-title__title">
                  अद्भुत प्राचीन मंदिरों की सैर।
                </h3>
              </div>
            </div>
          </div>
          <div className="row gutter-y-30">
            {famous?.length > 0 &&
              famous?.slice(0, 6).map((blog) => {
                const truncatedDescription = truncateText(
                  blog.shortdescription,
                  20
                );

                return (
                  <div
                    key={blog._id}
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="100ms"
                  >
                    <div className="donation-one__item">
                      <Link
                        to={`/temple-detail/${blog._id}`}
                        className="donation-one__item__image"
                      >
                        {/* <img src={`blog.mainImage`} alt="sanatan sewa nyas" /> */}
                        <img
                          //  src={section22}
                          src={`${process.env.REACT_APP_BASE_URL}/temples/${blog?.mainImage?.image}`}
                          alt="sanatan sewa nyas"
                        />
                        <div className="donation-one__item__cats">
                          {formatDate(blog.establishedDate)}
                        </div>
                      </Link>
                      <div className="donation-one__item__content">
                        <h3 className="donation-one__item__title">
                          <Link to={`/temple-detail/${blog._id}`}>
                            {blog.title.length > 15
                              ? blog.title.slice(0, 18) + "..."
                              : blog.title}
                          </Link>
                        </h3>
                        {}
                        <p className="donation-one__item__text">
                          {truncatedDescription}
                        </p>
                        <div className="">
                          <Link
                            to={`/event-detail/${blog._id}`}
                            className="blog-card__link mt-4"
                          >
                            {/* Read more{" "} */}
                            और पढ़ें...
                            <i className="icon-right-arrow ">
                              <FontAwesomeIcon icon={faArrowRightLong} />
                            </i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
      <section className="event-one pt-5 pb-5">
        <div className="container">
          <div className="row gutter-y-30 p-1">
            <div
              className="col-lg-4 col-md-12 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div className="sec-title text-left mt-4">
                <h6 className="sec-title__tagline">आगामी कार्यक्रम।</h6>
                <h3 className="sec-title__title">
                  हमारे आगामी कार्यक्रम और प्रोगाम।
                </h3>
              </div>
              <p className="event-one__text">
                हमारे आगामी कार्यक्रम जैसे कि कल्याण उपाय, शैक्षिक कार्यक्रम,
                सेमिनार, वर्कशॉप, सांस्कृतिक कार्यक्रम, त्योंहारों का उत्सव,
                इत्यादि के बारे में नियमित अपडेट्स के लिए हमारी वेबसाइट पर बने
                रहें।
              </p>
            </div>

            {eventSlide?.length > 1 &&
              eventSlide.slice(0, 2).map((item) => {
                return (
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="200ms"
                  >
                    <div className="event-one__item">
                      <Link to={`/event-detail/${item._id}`}>
                        <img
                          // src={`http://localhost:8080/events/${item.eventImage}`}
                          src={`${process.env.REACT_APP_BASE_URL}/events/${item?.eventImage?.image}`}
                          // src={section21}
                          alt={item.title}
                          className="UpcomingEvent"
                        />
                      </Link>
                      <div className="event-one__item__content">
                        <div className="event-one__item__date">
                          <img src={logo} alt="Logo" width={"60px"} />
                        </div>
                        <div className="event-one__item__content__bottom">
                          <ul className="event-one__item__meta">
                            <li>
                              <i className="fas fa-clock"></i>
                              {formatDate(item.startDate)}
                            </li>
                            <li>
                              <i className="fas fa-map-marker"></i>
                              {item.location.city}
                              {"  "}
                              {item.location.state}
                            </li>
                          </ul>
                          <h3 className="event-one__item__title">
                          <Link to={`/event-detail/${item._id}`}> <a href="event-details.html">{item.title}</a> </Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
      <section className="funfact-two">
        <div
          className="funfact-two__bg jarallax"
          data-jarallax
          data-speed="0.3"
          data-imgPosition="50% -100%"
        ></div>
        <div className="container">
          <div className="row p-5">
            <div className="col-xl-8 col-lg-7 col-md-6">
              <div className="funfact-two__content">
                <Link
                  to={"https://www.youtube.com/@SanatanSewaNyas"}
                  target="_blank"
                  className="video-popup"
                >
                  <FontAwesomeIcon icon={faPlay} size="xl" />
                  <span className="video-popup__border-one"></span>
                  <span className="video-popup__border-two"></span>
                </Link>
                <div className="funfact-two__content__shape">
                  <img src={arrow1} alt="sanatanSewaNyas" />
                </div>
                <div className="sec-title text-left">
                  <h6 className="sec-title__tagline">
                    आपका विश्वसनीय गैर-लाभकारी संस्थान।
                  </h6>
                  <h3 className="sec-title__title">
                    जानिए हमें क्या खास बनाता है।
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="fund-one">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="fund-one__image">
                <img src={section21} alt="sanatanSewaNyas" />
              </div>
              <div className="row fund-one__cus-row">
                <div className="col-md-12">
                  <div className="fund-one__info">
                    <div className="fund-one__info__icon">
                      <FontAwesomeIcon icon={faHandsPraying} />
                    </div>
                    <h3
                      className="fund-one__info__title"
                      style={{ lineHeight: "1.5" }}
                    >
                      धर्म और संस्कृति संरक्षण के लिए दान करें।
                    </h3>
                  </div>
                </div>
                <div className="col-md-12">
                  <p className="fund-one__info-text">
                    आप सनातन सेवा न्याय को दान देकर अपने अध्यात्मिक और समाजिक
                    कर्तव्यों को निभाएं। और असीम शांति और समृद्धि को अनुभव करें।
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="fund-one__form-wrapper">
                <div
                  className="fund-one__form-wrapper__bg"
                  style={{
                    backgroundImage: "url(assets/images/shapes/fund-bg.png)",
                  }}
                ></div>
                <div className="sec-title text-center">
                  <h6 className="sec-title__tagline">दान करें।</h6>
                  <h3 className="sec-title__title">
                    एक बेहतर उद्देश्य के लिए सहयोग करें!
                  </h3>
                </div>
                <p className="fund-one__form-wrapper__text">
                  आपका उदार दान जीवनों को परिवर्तित कर सकता है और जरूरतमंद
                  समुदायों की मदद कर सकता है।
                </p>
                <form className="fund-one__form" onSubmit={handleSubmit}>
                  <div className="fund-one__form__amount">
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      placeholder="नाम"
                      onChange={handleChange}
                    />
                    {errors.name && (
                      <span className="error">{errors.name}</span>
                    )}

                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      placeholder="ईमेल"
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <span className="error">{errors.email}</span>
                    )}

                    <input
                      type="tel"
                      name="phone"
                      value={formData.phone}
                      placeholder="फोन"
                      onChange={handleChange}
                    />
                    {errors.phone && (
                      <span className="error">{errors.phone}</span>
                    )}

                    <input
                      type="text"
                      name="amount"
                      value={formData.amount}
                      placeholder="1 रुपये ₹ - 5 लाख ₹ "
                      className="fund-one__form__amount__input"
                      onChange={handleChange}
                    />
                    {errors.amount && (
                      <span className="error">{errors.amount}</span>
                    )}
                  </div>
                  <button
                    className="sanatanSewaNyas-btn"
                    onClick={handleSubmit}
                  >
                    <span className="text-black">
                      {/* Donate Now */}
                      अभी दान दें!
                    </span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section
        className="testimonials-two mt-5"
        style={{
          backgroundImage: "url(assets/images/shapes/testimonial-bg-2.jpg)",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="testimonials-two__content">
                <div className="sec-title text-left">
                  <h6 className="sec-title__tagline">हमारे प्रशंसापत्र</h6>
                  <h3 className="sec-title__title">
                    सेवानिवृत्ति और दान की प्रेरणादायक कहानियाँ।
                  </h3>
                </div>

                <div className="testimonials-two__custome-navs"></div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="sanatanSewaNyas-stretch-element-inside-column">
                <Slider
                  {...testimonilos}
                  className="testimonials-two__carousel sanatanSewaNyas-owl__carousel sanatanSewaNyas-owl__carousel--with-shadow"
                >
                  {testimonials.map((testimonial, index) => (
                    <div className="item" key={index}>
                      <div className="testimonials-two__item">
                        <div className="testimonials-two__item__author">
                          <img src={section21} alt="sanatanSewaNyas" />
                        </div>

                        <div className="testimonials-two__item__ratings">
                          {Array(testimonial.rating)
                            .fill()
                            .map((_, i) => (
                              <span className="fa fa-star" key={i}></span>
                            ))}
                        </div>
                        <div>
                          <div className="testimonials-two__item__content">
                            {testimonial.content}
                          </div>
                          <h3 className="testimonials-two__item__meta">
                            {testimonial.author}
                          </h3>
                          <h2 className="testimonials-two__item__quatation">
                            “
                          </h2>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="blog-one">
        <div className="container">
          <div className="sec-title text-center">
            <h6 className="sec-title__tagline">पिछले आयोजन।</h6>
            <h3 className="sec-title__title">
              हमारे पिछले आयोजन और कार्यक्रम ।
            </h3>
          </div>
          <Slider
            {...settings}
            className="blog-one__carousel sanatanSewaNyas-owl__carousel sanatanSewaNyas-owl__carousel--with-shadow sanatanSewaNyas-owl__carousel--basic-nav"
          >
            {allEvent?.length > 0 &&
              allEvent?.map((item) => {
                console.log("lastevent", allEvent);

                return (
                  <div className="item" key={item}>
                    <div
                      className="blog-card wow fadeInUp"
                      data-wow-duration="1500ms"
                    >
                      {/* <div className="blog-card__date">
                        {formatDate(item.startDate)}
                      </div> */}
                      {"                                "}
                      {/* <div className="blog-card__date d-flex justify-between">
                        <div>समाप्ति तिथि -- {formatDate(item.endDate)} </div>
                      </div> */}
                      <div>
                        {/* <img
                          src={item.eventImage}
                          alt={item.title}
                          width={"100%"}
                        /> */}
                        <img 
                         src={`${process.env.REACT_APP_BASE_URL}/events/${item?.eventImage?.image}`}
                        // src={section22}
                         alt={item.title} width={"100%"} style={{height:"200px"}} />
                        <Link to={`/event-detail/${item._id}`}>
                          <span className="sr-only">{item.title}</span>
                        </Link>
                      </div>
                      <div className="blog-card__content">
                         <span className="">
                              {formatDate(item.endDate)}
                            </span>
                        <ul className="list-unstyled blog-card__meta">
                          <li>
                            <Link to={`/event-detail/${item._id}`}>
                              {item.location.city}
                            </Link>
                            ,
                          </li>
                          <li>
                            <Link to={`/event-detail/${item._id}`}>
                              {item.location.state}
                            </Link>
                          </li>
                        </ul>
                        <ul className="list-unstyled blog-card__meta">
                          <li>
                            <Link to={`/event-detail/${item._id}`}>
                              {/* {item.organizer} */}
                              {/* {user[item.organizer] || 'Unknown'} */}
                            </Link>
                          </li>
                        </ul>
                        <h3 className="blog-card__title">
                          <a href={item.link}>{item.title}</a>
                        </h3>
                        <Link
                          to={`/event-detail/${item._id}`}
                          className="blog-card__link"
                        >
                          {/* Read more{" "} */}
                          और पढ़ें...
                          <i className="icon-right-arrow">
                            <FontAwesomeIcon icon={faArrowRightLong} />
                          </i>
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
          </Slider>
        </div>
      </section>
    </Layout>
  );
}

export default Home;
