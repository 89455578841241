import React from "react";
import { Helmet } from "react-helmet";
import Header from "./HeaderTwo";
import Footer from "./Footer";
import HeaderTwo from "./HeaderTwo";

const Layout = ({ children, title, discription, keywords, author }) => {
  return (
    <div className="position-relative">
      <HeaderTwo />
      <Helmet>
        <meta name="description" content={discription} />
        <meta name="keywords" content={keywords} />
        <meta name="author" content={author} />
        <title>{title}</title>
      </Helmet>
      <main className="page-wrapper" style={{ marginTop: "110.5px" }}>
        {children}
      </main>
      <Footer />
    </div>
  );
};

Layout.defaultProps = {
  title: "Sanatan sewanyas",
  discription: "Hindu temples",
  keywords: "Sanskriti , Hindu",
  author: "Shivom Mishra",
};
export default Layout;
