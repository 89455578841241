import React, { useState } from "react";
import Layout from "../../Components/Layout";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const validate = () => {
    const newErrors = {};
    if (!formData.name) {
      newErrors.name = "Name is required";
    } else if (!/^[A-Za-z\s]+$/.test(formData.name)) {
      newErrors.name = "Name can only contain alphabets and spaces";
    }
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email address is invalid";
    }
    if (!formData.phone) {
      newErrors.phone = "Phone is required";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be 10 digits";
    }
    if (!formData.subject) newErrors.subject = "Subject is required";
    if (!formData.message) newErrors.message = "Message is required";
    return newErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      // No errors, submit the form
      console.log("Form data:", formData);
      // Reset form data
      setFormData({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      });
      setErrors({});
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <Layout
      title="Contact-Us"
      discription={"Sanatan Sewanyas Contact"}
      keywords={"Sanatan number,email sanatan sewanyas"}
    >
      <section className="page-header">
        <div className="page-header__bg"></div>
        <div className="container">
          <ul className="sanatanSewaNyas-breadcrumb list-unstyled">
            <li>
              <a href="index.html">Home</a>
            </li>
            <li>
              <span>Contact</span>
            </li>
          </ul>
          <h2 className="page-header__title">
            {/* Contact Page */}संपर्क
            </h2>
        </div>
      </section>
      <section className="contact-one">
        <div className="container">
          <div
            className="contact-one__inner"
            style={{
              backgroundImage: "url(assets/images/shapes/contact-bg.jpg)",
            }}
          >
            <div className="contact-one__inner__shape"></div>
            <div className="row">
              <div className="col-xl-12 d-flex flex-column align-items-center">
                <div className="sec-title text-center">
                  <h6 className="sec-title__tagline">
                    {/* Contact with us */}हमसे संपर्क करें
                    </h6>
                  <h3 className="sec-title__title">
                    {/* Feel free to write anytime */}
                    हमसे संपर्क करने में संकोच न करें।
                  </h3>
                </div>
                <form
                  className="contact-one__form contact-form-validated wow fadeInUp"
                  data-wow-duration="1500ms"
                  onSubmit={handleSubmit}
                >
                  <div className="form-one__group">
                    <div className="form-one__control">
                      <input
                        type="text"
                        name="name"
                        // placeholder="Your name"
                        placeholder="आपका नाम"
                        value={formData.name}
                        onChange={handleChange}
                      />
                      {errors.name && <p className="error">{errors.name}</p>}
                    </div>
                    <div className="form-one__control">
                      <input
                        type="email"
                        name="email"
                        // placeholder="Email address"
                        placeholder="ईमेल पता"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {errors.email && <p className="error">{errors.email}</p>}
                    </div>
                    <div className="form-one__control">
                      <input
                        type="text"
                        name="phone"
                        // placeholder="Phone"
                        placeholder="फोन"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                      {errors.phone && <p className="error">{errors.phone}</p>}
                    </div>
                    <div className="form-one__control">
                      <input
                        type="text"
                        name="subject"
                        // placeholder="Subject"
                        placeholder="विषय"
                        value={formData.subject}
                        onChange={handleChange}
                      />
                      {errors.subject && (
                        <p className="error">{errors.subject}</p>
                      )}
                    </div>
                    <div className="form-one__control form-one__control--full">
                      <textarea
                        name="message"
                        // placeholder="Write a message"
                        placeholder="संदेश लिखें"
                        value={formData.message}
                        onChange={handleChange}
                      ></textarea>
                      {errors.message && (
                        <p className="error">{errors.message}</p>
                      )}
                    </div>
                    <div className="form-one__control form-one__control--full">
                      <button type="submit" className="sanatanSewaNyas-btn">
                        <span>
                          {/* Send Message */}संदेश भेजें
                          </span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-map p-0">
        <div className="container-fluid">
          <div className="google-map google-map__contact">
            <iframe
              title="template google map"
              src="        https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7437.97565394935!2d72.7539900935791!3d21.232331300000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04c7bf04d994d%3A0x4ef8d3fae5f62689!2sOkheshwar%20Mahadev%20Temple!5e0!3m2!1sen!2sin!4v1718870397577!5m2!1sen!2sin"
              className="map__contact"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Contact;
