import React,{useState,useEffect} from 'react'
import Layout from "../../Components/Layout";
import axios from 'axios';
import section22 from "../../Assets/Images/homeBanner/ram-mandir.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import {
    FacebookShareButton,
    TwitterShareButton,
    
  } from 'react-share';
import styled from 'styled-components';
function Videogallery() {
    const [team, setTeam] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [user,setuser]=useState("");

    const fetchteam = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/get-all-videogallery`);
         
        setTeam(response.data?.data);
        console.log("team", response.data?.data)
        console.log("teames",team)
        
      } catch (err) {
        setError("Failed to fetch team release");
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      
      fetchteam();
    }, []);

 
  return (
    <Layout
    title="Events"
    discription={"Events of  Sanatan sewanyas"}
    keywords={"Events Sanatan Sewanyas"}
  >
    <section className="page-header">
      <div className="page-header__bg"></div>
      <div className="container">
        <ul className="sanatanSewaNyas-breadcrumb list-unstyled">
          <li>
            <a href="index.html">Media</a>
          </li>
          <li>
            <span>Video Gallery </span>
          </li>
        </ul>
        <h2 className="page-header__title">
        वीडियो गैलरी
          </h2>
      </div>
    </section>
    <section className="blog-one blog-one--page">
      <div className="container">
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>{error}</p>
        ) : team?.length === 0 ? (
          <p>No Events found</p>
        ) : (
          <div className="row gutter-y-30">
            {team?.length > 0 &&
              team?.map((team, index) =>{
              
                
               return(
                
                <div className="col-md-6 col-lg-4"  key={index}>
                  <div
                    className="videoblog-card wow fadeInUp p-3"
                    data-wow-duration="1500ms"
                    data-wow-delay="00ms"
                  >
                 
                    <div className="blog-details__image2">

                      <iframe  width={380} height={220} src={team.src} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                    <div className="blog-card__content">
                    <div>
                      <h6 className="text-center">
                          {team.title}
                      </h6>
                    </div>
                
                      
                   
                    
                    </div>
                  </div>
                </div>
              )})}
          </div>
        )}
      </div>
    </section>
  </Layout>
  )
}

export default Videogallery