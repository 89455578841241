import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import About from "./Layout/About";
import Contact from "./Layout/Contact";
import Donate from "./Layout/Donate";
import Donation from "./Layout/Donation";
import EventDetail from "./Layout/EventDetails";
import Events from "./Layout/Events";
import Home from "./Layout/Home";
import Temples from "./Layout/Temples";
import Joinus from "./Layout/joinus/Joinus";
import TempleDetails from "./Layout/TempleDetails";
import AdminRoutes from "./ProtectedRoute/AdminRoutes";
import AdminDashboard from "./Layout/Admin/AdminDashboard";
import CreateTemple from "./Layout/Admin/create-temple";
import CreateEvents from "./Layout/Admin/Create-events";
import Press from "./Layout/pressrelease/Press";
import PressReleaseDetail from "./Layout/PressReleaseDetail/PressReleaseDetail";
import Ourteam from "./Layout/ourteam/Ourteam";
import Videogallery from "./Layout/videogallery/Videogallery";
import "react-toastify/dist/ReactToastify.css";
import Imagegallery from "./Layout/imagegallery/Imagegallery";
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, []]);

  return null;
};

function App() {
  return (
    <>
      <ToastContainer />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/donation" element={<Donation />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/events" element={<Events />} />
        <Route path="/event-detail/:id" element={<EventDetail />} />
        <Route path="/joinus" element={<Joinus/>} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/temples" element={<Temples />} />
        <Route path="/press-release" element={<Press/>} />
        <Route path="/press-release-detail/:id" element={<PressReleaseDetail/>} />
        <Route path="/ourteam" element={<Ourteam/>} />
        <Route path="/videogallery" element={<Videogallery/>} />
        <Route path="/imagegallery" element={<Imagegallery/>} />
        <Route path="/temple-detail/:id" element={<TempleDetails />} />
        <Route path="/*" element={<Home />} />
        <Route path="/dashboard" element={<AdminRoutes />}>
          <Route path="admin" element={<AdminDashboard />} />
          <Route path="create-temple" element={<CreateTemple />} />
        </Route>
        <Route path="/dashboard/create-events" element={<CreateEvents />} />
      </Routes>
    </>
  );
}

export default App;
