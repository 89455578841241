import Layout from "../../Components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import section22 from "../../Assets/Images/homeBanner/ram-mandir.png";

function Events() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user,setuser]=useState("");
  const fetchEvents = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/get-all-events`
      );
       
      setEvents(response.data?.data);
       
      const organizerIds = [...new Set(events.map(event => event.organizer))];
     
      // Fetch user data for each organizer
      const userResponses = await Promise.all(
        organizerIds.map(id => axios.get(`${process.env.REACT_APP_BASE_URL}/user-by-id/${id}`))
      );

      // Debug: Log the responses to see their structure
      console.log('User responses:', userResponses);

      const usersData = {};

      for (const userId in userResponses) {
     
        const response = userResponses[userId];
        console.log("first",response.data)
        if (response.status === 200) {
          const userData = response.data;
        
          if (userData && userData._id && userData.name) {
            usersData[userData._id] = userData.name;
          }
        }
      }

      if (Object.keys(usersData).length > 0) {
        setuser(usersData);
      }
      
    } catch (err) {
      setError("Failed to fetch Events");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    
    fetchEvents();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };


 
  return (
    <Layout
      title="Events"
      discription={"Events of  Sanatan sewanyas"}
      keywords={"Events Sanatan Sewanyas"}
    >
      <section className="page-header">
        <div className="page-header__bg"></div>
        <div className="container">
          <ul className="sanatanSewaNyas-breadcrumb list-unstyled">
            <li>
              <a href="index.html">Home</a>
            </li>
            <li>
              <span>Events</span>
            </li>
          </ul>
          <h2 className="page-header__title">
            {/* Events Page */}कार्यक्रम / आयोजन
            </h2>
        </div>
      </section>
      <section className="blog-one blog-one--page">
        <div className="container">
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>{error}</p>
          ) : events.length === 0 ? (
            <p>No Events found</p>
          ) : (
            <div className="row gutter-y-30">
              {events?.length > 0 &&
                events?.map((event, index) => (
                  <div className="col-md-6 col-lg-4  key={index}">
                    <div
                      className="blog-card wow fadeInUp"
                      data-wow-duration="1500ms"
                      data-wow-delay="00ms"
                    >
                      {/* <div className="blog-card__date">
                        {formatDate(event.startDate)}
                      </div> */}
                      <div className="blog-card__image">
                        {/* <img src={section22} alt={event.title} /> */}
                        <img 
                     src={`${process.env.REACT_APP_BASE_URL}/events/${event?.eventImage?.image}`} 
                    // src={section21}
                     alt={event.title} />
                        {/* <img
                          src={`http://localhost:8080/events/${event.eventImage}`}
                          alt={event.title}
                        />
                        <img
                          src={`http://localhost:8080/events/${event.eventImage}`}
                          alt={""}
                        /> */}
                      </div>
                      <div className="blog-card__content">
                          <span className="">
                              {formatDate(event.startDate)}
                            </span>
                        <ul className="list-unstyled blog-card__meta mt-2">
                          <li>
                            <i className="fa fa-user-circle"></i>
                            <a href="blog.html">
                              {/* {event.organizer} */}
                            {/* {user[event?.organizer]? user[event?.organizer] : 'Unknown'} */}
                            </a>
                          </li>
                          <li>
                            <i className="fa fa-comments"></i>
                            {event.location.city}
                          </li>
                          <li>
                            <i className="fa fa-comments " id="listeve"></i>
                            {event.location.state}
                          </li>
                        </ul>
                        <h3 className="blog-card__title">
                          <a href="blog-details.html">{event.title}</a>
                        </h3>
                        <Link
                          to={`/event-detail/${event._id}`}
                          className="blog-card__link"
                        >
                          Read more
                          <i className="icon-right-arrow">
                            {" "}
                            <FontAwesomeIcon icon={faArrowRightLong} />
                          </i>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </section>
    </Layout>
  );
}

export default Events;
