import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import { useParams } from "react-router-dom";
import axios from "axios";
import section21 from "../../Assets/Images/homeBanner/amarnathh.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import member from "../../Assets/Images/Logo/snatan-seva-logo.png";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';
function EventDetail() {
  const [eventData, setEventData] = React.useState([]);
  const [user, setuser] = useState('')
  const { id } = useParams();
  const getEvent = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/get-event/${id}`
      );
      const ids = response.data?.data[0].organizer
      const userdata = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/user-by-id/${ids}`
      );
      setuser(userdata.data)
      console.log("user", userdata.data)
      setEventData(response.data?.data[0]);
      console.log("data", response.data?.data[0].organizer)
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getEvent();
  }, []);
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name) {
      newErrors.name = "Name is required";
    } else if (!/^[A-Za-z\s]+$/.test(formData.name)) {
      newErrors.name = "Name can only contain alphabets and spaces";
    }
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email address is invalid";
    }
    if (!formData.message) newErrors.message = "Message is required";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/submit-comment`,
          {
            templeId: id,
            ...formData,
          }
        );

        setErrors({});
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message === "Your comment is already submitted"
        ) {
          setErrors({ api: "Your comment is already submitted" });
        } else {
          console.log(error);
          setErrors({ api: "Failed to submit comment" });
        }
      }
    } else {
      setErrors(validationErrors);
    }
  };


  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Layout
      title="Event-Details"
      discription={"Events of  Sanatan sewanyas"}
      keywords={"Events Sanatan Sewanyas"}
    >
      <section className="page-header">
        <div className="page-header__bg"></div>
        <div className="container">
          <ul className="sanatanSewaNyas-breadcrumb list-unstyled">
            <li>
              <a href="index.html">Home</a>
            </li>
            <li>
              <span>Events</span>
            </li>
          </ul>
          {eventData && eventData?.title && (<h2 className="page-header__title">{eventData && eventData?.title}</h2>)}

        </div>
      </section>
      <section className="blog-details">
        <div className="container">
          <div key={eventData?._id} className="row gutter-y-60">
            <div className="col-lg-8">
              <div className="blog-details__content">
                <div className="blog-details__image">
                  <img
                    // src={`http://localhost:8080/events/${eventData.eventImage}`}
                    // src={section21}
                    src={`${process.env.REACT_APP_BASE_URL}/events/${eventData?.eventImage?.image}`}
                    alt={eventData?.title}
                  />
                </div>
                <div className="d-flex justify-content-between">
                  <div className="blog-card__date">
                    {/* Start Date */}
                    आरंभ तिथि -- {formatDate(eventData?.startDate)}
                  </div>{" "}
                  {"           "}
                  <div className="blog-card__date">
                    {/* End Date  */}
                    समाप्ति तिथि -- {formatDate(eventData?.endDate)}
                  </div>
                </div>
                <h3 className="blog-details__title">{eventData?.title}</h3>
                <h5>{user?.name}</h5>
                <p className="blog-details__text">{eventData?.description}</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar">
                <aside className="widget-area">
                  <div className="sidebar__single">
                    <h6 className="sidebar__title">
                      {/* Commite Member */}समिति सदस्य
                    </h6>
                    <ul className="sidebar__posts list-unstyled">
                      <li className="sidebar__posts__item">
                        <div className="sidebar__posts__image">
                          <img src={member} alt="blog" />
                        </div>
                        <div className="sidebar__posts__content pt-3">
                          <h4 className="sidebar__posts__title">
                            <a href="blog-details.html">
                              {/* Rajat Rai */}रजत राय
                            </a>
                          </h4>
                          <p className="sidebar__posts__subtitle">
                            {/* Chief */}प्रमुख
                          </p>
                        </div>
                      </li>
                      <li className="sidebar__posts__item">
                        <div className="sidebar__posts__image">
                          <img src={member} alt="blog" />
                        </div>
                        <div className="sidebar__posts__content pt-3">
                          <h4 className="sidebar__posts__title">
                            <a href="blog-details.html">
                              {/* Suraj Rao */}सूरज राय
                            </a>
                          </h4>
                          <p className="sidebar__posts__subtitle">
                            {/* Expert */}विशेषज्ञ
                          </p>
                        </div>
                      </li>
                      <li className="sidebar__posts__item">
                        <div className="sidebar__posts__image">
                          <img src={member} alt="blog" />
                        </div>
                        <div className="sidebar__posts__content pt-3">
                          <h4 className="sidebar__posts__title">
                            <a href="blog-details.html">
                              {/* Abhay Panjla */}अभय पंजला
                            </a>
                          </h4>
                          <p className="sidebar__posts__subtitle">
                            {/* Chief */}प्रमुख
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="sidebar__single">
                    <h4 className="sidebar__title">
                      <FontAwesomeIcon
                        icon={faLocationDot}
                        style={{ color: "#470604" }}
                      />{" "}
                      {/* Location */}स्थान
                    </h4>
                    <ul className="sidebar__categories list-unstyled">
                      <li>
                        <a href="blog.html">
                          <span>
                            {/* City  */}
                            शहर :
                          </span> {eventData?.location?.city}
                        </a>
                      </li>
                      <li>
                        <a href="blog.html">
                          {" "}
                          <span>
                            {/* State */}
                            राज्य : </span> {eventData?.location?.state}
                        </a>
                      </li>
                      <li>
                        <a href="blog.html">
                          {" "}
                          <span>
                            {/* Country */}
                            देश : </span> {eventData?.location?.country}
                        </a>
                      </li>
                      <li>
                        <a href="blog.html">
                          {" "}
                          <span>
                            {/* Postal Code  */}
                            पिन कोड : </span>{" "}
                          {eventData?.location?.zipcode}
                        </a>
                      </li>
                    </ul>
                  </div>
                </aside>
              </div>
            </div>
          </div>
          <div className="comments-form">
            <h3 className="comments-form__title">
              {/* Leave a comment */}
              टिप्पणी करें
            </h3>
            <form
              className="comments-form__form contact-form-validated form-one"
              onSubmit={handleSubmit}
            >
              <div className="form-one__group">
                <div className="form-one__control">
                  <input
                    type="text"
                    name="name"
                    placeholder="आपका नाम"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  {errors.name && <p className="error">{errors.name}</p>}
                </div>
                <div className="form-one__control">
                  <input
                    type="email"
                    name="email"
                    placeholder="ईमेल"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errors.email && <p className="error">{errors.email}</p>}
                </div>
                <div className="form-one__control form-one__control--full">
                  <textarea
                    name="message"
                    placeholder="संदेश लिखें"
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                  {errors.message && <p className="error">{errors.message}</p>}
                </div>
                <div className="form-one__control form-one__control--full">
                  <button type="submit" className="aofixo-btn">
                    <span>
                      {/* Submit comment */}टिप्पणी सबमिट करें
                    </span>
                  </button>
                </div>
              </div>
              {errors.api && <p className="error">{errors.api}</p>}
            </form>
            <div className="result"></div>
          </div>
        </div>
      </section>

{/*     
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>User Details</DialogTitle>
        <DialogContent>
          <Typography variant="h6">Name:</Typography>
          <Typography variant="body1">{user.name}</Typography>
          <Typography variant="h6">Email:</Typography>
          <Typography variant="body1">{user.email}</Typography>
          <Typography variant="h6">Phone:</Typography>
          <Typography variant="body1">{user.phone}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog> */}
    </Layout>
  );
}

export default EventDetail;
