import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import member from "../../Assets/Images/Logo/snatan-seva-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import TempleDetailsImage from "../../Assets/Images/homeBanner/ramMandir.png";
import templeBannerImage from "../../Assets/Images/homeBanner/temple-detail-bg.png";
import support from "../../Assets/Images/Shapes/support.png";
import commentImage1 from "../../Assets/Images/Logo/snatan-seva-logo.png";
import Slider from "react-slick";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
} from "react-share";
import {
  faFacebook,
  faInstagram,
  faWhatsapp,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
function TempleDetails() {
  const [templeData, setTempleData] = React.useState([]);
  const [images,setimages]=useState([])
  const [comments, setComments] = useState([]);
  const { id } = useParams();
  const getTemples = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/get-temple/${id}`
      );
      // `http:/testapi.nextworktechnologies.com`
      setTempleData(response.data?.data[0]);
      
      const data=response.data?.data[0]
      console.log("templeid",data)
      
      const extractImages = (data) => {
        const images = [];
      
        if (data.mainImage?.image) {
          images.push(data.mainImage.image);
        }
      
        if (data.bannerImage?.image) {
          images.push(data.bannerImage.image);
        }
      
        ['sub1', 'sub2', 'sub3'].forEach((key) => {
          if (data[key]?.image) {
            images.push(data[key].image);
          }
        });
      
        return images;
      };
      
    
      // Extracting images
      const imagesArray = extractImages(data);
      setimages(imagesArray)
      // Output the images array
      console.log('images',images);
    } catch (error) {
      console.log(error);
    }
  };
  const getComments = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/get-comments/${id}`
      );
      setComments(response.data.comments);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getTemples();
    getComments();
  }, []);
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    // Replace this URL with your API endpoint
    // fetch(`https:/testapi.nextworktechnologies.com/api/v1//get-temple/${id}`)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     // Assuming the API response has a property called `bannerImage`
    //     setBannerImage(data.bannerImage);
    //   })
    //   .catch((error) => console.error("Error fetching banner image:", error));
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name) {
      newErrors.name = "Name is required";
    } else if (!/^[A-Za-z\s]+$/.test(formData.name)) {
      newErrors.name = "Name can only contain alphabets and spaces";
    }
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email address is invalid";
    }
    if (!formData.message) newErrors.message = "Message is required";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/submit-comment`,
          {
            templeId: id,
            ...formData,
          }
        );

        setErrors({});
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message === "Your comment is already submitted"
        ) {
          setErrors({ api: "Your comment is already submitted" });
        } else {
          console.log(error);
          setErrors({ api: "Failed to submit comment" });
        }
      }
    } else {
      setErrors(validationErrors);
    }
  };

  // const images = [
  //   TempleDetailsImage,
  //   TempleDetailsImage,
  //   TempleDetailsImage,
  //   // Add more image paths as needed
  // ];
  const sliderSettings = {
    customPaging: function (i) {
      return (
        <a key={i}>
          <img 
          // src={images[i]}
          style={{ height: '100px'}}  
          src={`${process.env.REACT_APP_BASE_URL}/temples/${images[i]}`}
          alt={`Thumbnail ${i + 1}`} />
        </a>
      );
    },
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: "slick-dots slick-thumb",
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };

  const facebookurl = "https://www.facebook.com/TempleConnect/";
  const twitterurl = "https://x.com/home";
  const title = "Check out my Instagram account!";
  return (
    <Layout
      title="Temple-Detail"
      discription={"Hindu Temple "}
      keywords={"Hindu Temple"}
    >
      <section className="page-header">
        <div
          className="page-header__bg"
          // style={{ backgroundImage: `url(${bannerImage})` }}
          style={{ backgroundImage: `url(${templeBannerImage})` }}
        ></div>
        <div className="container">
          <ul className="sanatanSewaNyas-breadcrumb list-unstyled">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <span>Temple</span>
            </li>
          </ul>
          <h2 className="page-header__title">{templeData.title}</h2>
        </div>
      </section>
      <section className="blog-details">
        <div className="container">
          <div key={templeData._id} className=" row gutter-y-60">
            <div className="col-lg-8">
              <div className="blog-details__content">
                <Slider {...sliderSettings}>
                  {images.map((image, index) => (
                    <div key={index}>
                      <img
                        style={{ height: '400px'}}  
                      //  src={image}
                       src={`${process.env.REACT_APP_BASE_URL}/temples/${image}`}
                        alt={`Slide ${index + 1}`} />
                    </div>
                  ))}
                </Slider>
                <div className="d-flex justify-content-between">
                  <div className="blog-card__date">
                    {formatDate(templeData.establishedDate)}
                  </div>
                  <div className="btn_wrap">
                    <span>Share</span>
                    <div className="d-flex gap-3">
                      <Link to={""}>
                        <FacebookShareButton url={facebookurl} title={title}>
                          <FontAwesomeIcon
                            icon={faFacebook}
                            size="sm"
                            color="black"
                          />
                        </FacebookShareButton>
                      </Link>
                      <Link to={""}>
                        <FontAwesomeIcon
                          icon={faInstagram}
                          color="black"
                          size="sm"
                        />
                      </Link>
                      <Link to={""}>
                        <TwitterShareButton url={twitterurl}>
                          <FontAwesomeIcon
                            icon={faXTwitter}
                            size="sm"
                            color="black"
                          />
                        </TwitterShareButton>
                      </Link>
                      <Link to={""}>
                        <WhatsappShareButton>
                          <FontAwesomeIcon
                            icon={faWhatsapp}
                            size="sm"
                            color="black"
                          />
                        </WhatsappShareButton>
                      </Link>
                    </div>
                  </div>
                </div>

                <h3 className="blog-details__title">{templeData.title}</h3>
                <p className="blog-details__text">{templeData.description}</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar">
                <aside className="widget-area">
                  <div className="sidebar__single">
                    <h4 className="sidebar__title">
                      <FontAwesomeIcon
                        icon={faLocationDot}
                        style={{ color: "#470604" }}
                      />{" "}
                      {/* Location */}स्थान
                    </h4>
                    <ul className="sidebar__categories list-unstyled">
                      <li>
                        <Link to={templeData.location} target="_blank">
                          <span>
                            {/* City */}
                            शहर :{" "}
                          </span>{" "}
                          {templeData.city}
                        </Link>
                      </li>
                      <li>
                        <Link to={templeData.location} target="_blank">
                          {" "}
                          <span>
                            {/* State */}
                            राज्य :{" "}
                          </span>{" "}
                          {templeData.state}
                        </Link>
                      </li>
                      <li>
                        <Link to={templeData.location} target="_blank">
                          {" "}
                          <span>
                            {/* Country */}
                            देश :{" "}
                          </span>{" "}
                          {templeData.country}
                        </Link>
                      </li>
                      <li>
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3753.826839251649!2d85.81793859999999!3d19.804937900000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a19c6b8bfe386af%3A0x8f052c84639c7d48!2sShree%20Jagannatha%20Temple%20Puri!5e0!3m2!1sen!2sin!4v1719237264857!5m2!1sen!2sin"
                          width={600}
                          height={350}
                          style={{ border: 0 }}
                          allowFullScreen
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="sidebar__single">
                    <h4 className="sidebar__title">
                      {/* Commite Member */}
                      {/* समिति सदस्य */}
                      Contact Us
                    </h4>
                    <ul className="sidebar__posts list-unstyled">
                      <li className="sidebar__posts__item">
                        {/* <div className="sidebar__posts__image">
                          <img src={member} alt="blog" />
                        </div> */}
                        <div className="sidebar__posts__content">
                          <h4 className="sidebar__posts__title">
                            <a href="blog-details.html">
                              {/* Test1 */}
                             Contact
                            </a>
                          </h4>
                          <p>{/* Chief */}9988667213</p>
                        </div>
                      </li>
                      <li className="sidebar__posts__item">
                        {/* <div className="sidebar__posts__image">
                          <img src={member} alt="blog" />
                        </div> */}
                        <div className="sidebar__posts__content">
                          <h4 className="sidebar__posts__title">
                            <a href="blog-details.html">
                              {/* Test2 */} Email
                            </a>
                          </h4>
                          <p>{/* Expert */}Sanatan@gmail.com</p>
                        </div>
                      </li>
                      <li className="sidebar__posts__item">
                        {/* <div className="sidebar__posts__image">
                          <img src={member} alt="blog" />
                        </div> */}
                        <div className="sidebar__posts__content">
                          <h4 className="sidebar__posts__title">
                            <a href="blog-details.html">{/* Test3 */}Website</a>
                          </h4>
                          <p>{/* Chief */}Sanatanshreesamiti@org.com</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  {/* <ul className="list-unstyled mt-30">
                    <li className="blog-details__paginations__item">
                      <div className="blog-details__paginations__image">
                        <img src={support} alt="" />
                      </div>
                      <div className="blog-details__paginations__content">
                        <h4 className="blog-details__paginations__title">
                          <Link to={templeData.help} target="_blank">
                            {"  "}
                            सहायता के लिए यहां जाएं।
                          </Link>
                        </h4>
                      </div>
                    </li>
                  </ul> */}
                  {/* <ul className="list-unstyled mt-30">
                    <li className="blog-details__paginations__item">
                      <div className="blog-details__paginations__image">
                        <img src={support} alt="" />
                      </div>
                      <div className="blog-details__paginations__content">
                        <h4 className="blog-details__paginations__title">
                          <Link to={templeData.help} target="_blank">
                            {"  "}
                            सहायता के लिए यहां जाएं।
                          </Link>
                        </h4>
                      </div>
                    </li>
                  </ul> */}
                </aside>
              </div>
            </div>
          </div>
          <div className="comments-one">
            <h3 className="comments-one__title">
              {comments.length}
              {/* comments */} टिप्पणियाँ
            </h3>
            {comments.length > 0 ? (
              comments.slice(0, 2).map((comment) => (
                <ul
                  className="list-unstyled comments-one__list"
                  key={comment._id}
                >
                  <li className="comments-one__card">
                    <div className="comments-one__card__image">
                      <img src={commentImage1} alt="Kevin martin" />
                    </div>
                    <div className="comments-one__card__content">
                      <h3 className="comments-one__card__title">
                        {comment.name}
                      </h3>
                      <p className="comments-one__card__text">
                        {comment.message}
                      </p>
                    </div>
                  </li>
                </ul>
              ))
            ) : (
              <p>{/* No comments yet. */}अभी तक कोई टिप्पणी नहीं है।</p>
            )}
          </div>
          <div className="comments-form">
            <h3 className="comments-form__title">
              {/* Leave a comment */}एक टिप्पणी लिखें
            </h3>
            <form
              className="comments-form__form contact-form-validated form-one"
              onSubmit={handleSubmit}
            >
              <div className="form-one__group">
                <div className="form-one__control">
                  <input
                    type="text"
                    name="name"
                    // placeholder="Your name"
                    placeholder="आपका नाम"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  {errors.name && <p className="error">{errors.name}</p>}
                </div>
                <div className="form-one__control">
                  <input
                    type="email"
                    name="email"
                    // placeholder="Email address"
                    placeholder="ईमेल पता"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errors.email && <p className="error">{errors.email}</p>}
                </div>
                <div className="form-one__control form-one__control--full">
                  <textarea
                    name="message"
                    // placeholder="Write a message"
                    placeholder="संदेश लिखें"
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                  {errors.message && <p className="error">{errors.message}</p>}
                </div>
                {/* <div className="form-one__control form-one__control--full">
                  <button type="submit" className="aofixo-btn">
                    <span>टिप्पणी सबमिट करें</span>
                  </button>
                {/* </div> */} 
           
                  <button type="submit" className="aofixo-btn">
                    <span>{/* Submit comment */}टिप्पणी सबमिट करें</span>
                  </button>
               
              </div>
              {errors.api && <p className="error">{errors.api}</p>}
            </form>

            <div className="result"></div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default TempleDetails;
