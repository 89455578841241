import React,{useState,useEffect} from 'react'
import Layout from "../../Components/Layout";
import axios from 'axios';
import pressrelese from "../../Assets/Images/homeBanner/press-relese.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import {
    FacebookShareButton,
    TwitterShareButton,
    
  } from 'react-share';
function Press() {
    const [press, setPress] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [user,setuser]=useState("");
    const shareUrl = window.location.href;
    const fetchpress = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/get-all-pressreliese`);
         
        setPress(response.data?.data);
        console.log("press",response.data?.data)
        console.log("presses",press)
        
      } catch (err) {
        setError("Failed to fetch press release");
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      
      fetchpress();
    }, []);
  
    const formatDate = (dateString) => {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    };

    function truncateString(str, wordLimit) {
      // Split the string into an array of words
      const words = str.split(' ');
  
      // Check if the number of words is less than or equal to the word limit
      if (words.length <= wordLimit) {
          return str;
      }
  
      // Join the words up to the specified limit
      const truncated = words.slice(0, wordLimit).join(' ');
  
      // Add an ellipsis to indicate that the string has been truncated
      return truncated + '...';
  }
  
  return (
    <div>
    <Layout
      title="Events"
      discription={"Events of  Sanatan sewanyas"}
      keywords={"Events Sanatan Sewanyas"}
    >
      <section className="page-header">
        <div className="page-header__bg"></div>
        <div className="container">
          <ul className="sanatanSewaNyas-breadcrumb list-unstyled">
            <li>
              <a href="index.html">Media</a>
            </li>
            <li>
              <span>Press Release </span>
            </li>
          </ul>
          <h2 className="page-header__title">
            {/* Events Page */}प्रेस विज्ञप्ति
            </h2>
        </div>
      </section>
      <div className='row'>
        <div className='col-12 col-md-9 order-2 order-md-1'>
        <section className="blog-one blog-one--page">
        <div className="container">
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>{error}</p>
          ) : press.length === 0 ? (
            <p>No Events found</p>
          ) : (
            <div className="row gutter-y-30">
              {press?.length > 0 &&
                press?.map((press, index) =>{
                  const description= truncateString(press.content,15)
                  
                 return(
                  
                  <div className="col-md-6 col-lg-4 "  key={index}>
                    <div
                      className="blog-card wow fadeInUp"
                      data-wow-duration="1500ms"
                      data-wow-delay="00ms"
                    >
                      {/* <div className="blog-card__date">
                        {formatDate(event.startDate)}
                      </div> */}
                      <div className="">
                       
                        <img 
                        // src={`${process.env.REACT_APP_BASE_URL}/pressrelease/${press.image}`} 
                        style={{borderRadius:"20px"}}
                       src={pressrelese}
                        alt={press.title}
                      />
                        
                      </div>
                      <div className="blog-card__content">
                          <span className="">
                              {/* {formatDate(event.startDate)} */}
                            </span>
                        <ul className="list-unstyled blog-card__meta mt-2">
                    
                       
                        </ul>
                        <h3 className="blog-card__title">
                          <a >
                            {press.title}
                            </a>
                        </h3>
                   
                        <div className="my-2 d-flex justify-content-between">
                        <div>  {moment(press.createdAt).format('MMMM DD, YYYY')}</div>
                        <div>    <FacebookShareButton url={shareUrl}  className="share-button"><FacebookIcon/></FacebookShareButton>  <TwitterShareButton url={shareUrl} ><TwitterIcon className='ms-2'/></TwitterShareButton>      </div>
                     
                       
                           
                         
                      </div>
                      
                        <Link
                          to={`/press-release-detail/${press._id}`}
                          className="blog-card__link"
                        >
                          Read more
                          <i className="icon-right-arrow">
                            {" "}
                            <FontAwesomeIcon icon={faArrowRightLong} />
                          </i>
                        </Link>
                      </div>
                    </div>
                  </div>
                )})}
            </div>
          )}
        </div>
      </section>
        </div>
        <div className='col-12 col-md-3 order-1 order-md-2 mt-5'>
          <div className='row gutter-y-30'>
            <div className='col-12 mt-4'>
            <div
                      className="blog-card2 wow fadeInUp "
                      data-wow-duration="1500ms"
                      data-wow-delay="00ms"
                    >
                       <h3 className="">
                          <div className='d-flex justify-content-left align-items-center'>
                            <div  > Live </div>
                             <div className="ms-2" style={{height:"10px",width:"10px", color:"#000",borderRadius:"50%",backgroundColor:"red"}}></div>
                            </div>
                        </h3>
                      <div className="blog-card__image2">
                       
                      <iframe  height="200" src="https://www.youtube.com/embed/Kyq6u95izhY?si=1UXJDCykzKG3rSBX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        
                      </div>
                      <div className="blog-card__content">
                          <span className="">
                              {/* {formatDate(event.startDate)} */}
                            </span>
                        <ul className="list-unstyled blog-card__meta mt-2">
                          <li>
                            <i className="fa fa-user-circle"></i>
                            <a href="blog.html">
                         
                            </a>
                          </li>
                       
                        </ul>
                      
                        
      
                 
                      </div>
                </div>


               <div
                      className="blog-card2 wow fadeInUp my-4"
                      data-wow-duration="1500ms"
                      data-wow-delay="00ms"
                    >
                       <h3 className="blog-card__title">
                          Social Stream
                        </h3>
                        <hr/>
                      <div className="blog-card__image2">
                       
                      <iframe  height="200" src="https://www.youtube.com/embed/Kyq6u95izhY?si=1UXJDCykzKG3rSBX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        
                      </div>
          
                </div>    
            </div>
            
          </div>
      
        </div>
      </div>
  
    </Layout>
    </div>
  )
}

export default Press;