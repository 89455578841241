import React,{useState,useEffect} from 'react'
import Layout from "../../Components/Layout";
import axios from 'axios';
import section22 from "../../Assets/Images/homeBanner/ram-mandir.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import {
    FacebookShareButton,
    TwitterShareButton,
    
  } from 'react-share';
function Ourteam() {
    const [team, setTeam] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [user,setuser]=useState("");

    const fetchteam = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/socialmedia`);
         
        setTeam(response?.data);
        console.log("team", response.data?.data)
        console.log("teames",team)
        
      } catch (err) {
        setError("Failed to fetch team release");
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      
      fetchteam();
    }, []);
  return (
    <Layout
    title="Events"
    discription={"Events of  Sanatan sewanyas"}
    keywords={"Events Sanatan Sewanyas"}
  >
    <section className="page-header">
      <div className="page-header__bg"></div>
      <div className="container">
        <ul className="sanatanSewaNyas-breadcrumb list-unstyled">
          <li>
            <a href="index.html">Media</a>
          </li>
          <li>
            <span>Our team </span>
          </li>
        </ul>
        <h2 className="page-header__title">
          {/* Events Page */}हमारी टीम
          </h2>
      </div>
    </section>
    <section className="blog-one blog-one--page">
      <div className="container">
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>{error}</p>
        ) : team?.length === 0 ? (
          <p>No Events found</p>
        ) : (
          <div className="row gutter-y-30">
            {team?.length > 0 &&
              team?.map((team, index) =>{
              
                
               return(
                
                <div className="col-md-6 col-lg-4 "  key={index}>
                  <div
                    className="blog-card wow fadeInUp"
                    data-wow-duration="1500ms"
                    data-wow-delay="00ms"
                  >
                    {/* <div className="blog-card__date">
                      {formatDate(event.startDate)}
                    </div> */}
                    <div className="blog-card__image">
                     
                      <img 
                      src={`${process.env.REACT_APP_BASE_URL}/socialmedia/${team.image}`} 
                     // src={section22}
                      alt={team.title}
                    />
                      
                    </div>
                    <div className="blog-card__content">
                        <span className="">
                            {/* {formatDate(event.startDate)} */}
                          </span>
                      <ul className="list-unstyled blog-card__meta mt-2">
                        <li>
                          <i className="fa fa-user-circle"></i>
                          <a href="blog.html">
                       
                          </a>
                        </li>
                     
                      </ul>
                      <h3 className="blog-card__title">
                        <a href="blog-details.html">
                          {team.name}
                          </a>
                      </h3>
                      <span className="">
                            {/* {formatDate(team.date)} */}
                       </span>
                   
                      <div className="my-2 d-flex justify-content-between">
                        <div> {team.designation}</div>
                        <div>    <FacebookShareButton url={team.facebook}  className="share-button"><FacebookIcon/></FacebookShareButton>  <TwitterShareButton url={team.twitter}><TwitterIcon className='ms-2'/></TwitterShareButton>      </div>
                     
                       
                           
                         
                      </div>
                     
                      {/* <Link
                        to={`/team-release-detail/${team._id}`}
                        className="blog-card__link"
                      >
                        Read more
                        <i className="icon-right-arrow">
                          {" "}
                          <FontAwesomeIcon icon={faArrowRightLong} />
                        </i>
                      </Link> */}
                    </div>
                  </div>
                </div>
              )})}
          </div>
        )}
      </div>
    </section>
  </Layout>
  )
}

export default Ourteam