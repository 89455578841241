import React, { useState, useEffect } from "react";
import Layout from "../../Components/Layout";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import templeBannerImage from "../../Assets/Images/homeBanner/temple-detail-bg.png";
import section22 from "../../Assets/Images/homeBanner/ram-mandir.png";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
function Temples() {
  const [blogs, setBlogs] = useState([]);
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    if (value === 'reset' && checked) {
      resetFilter();
    } else {
      const updatedCategories = checked
        ? [...selectedCategories.filter(cat => cat !== 'reset'), value]
        : selectedCategories.filter(category => category !== value);

      setSelectedCategories(updatedCategories);
      setFilteredBlogs(
        blogs.filter(blog =>
          updatedCategories.length === 0 ||
          updatedCategories.includes(blog.category)
        )
      );
    }
  };
   // Function to handle filtering based on a condition (e.g., category)
   const handleFilter = (category) => {
    const filtered = blogs.filter(blog => blog.category === category);
    setFilteredBlogs(filtered);
    
  };

  // Function to handle filtering based on a condition (e.g., category)
  const handlecityFilter = (city) => {
    const filtered = blogs.filter(blog => blog.city=== city);
    setFilteredBlogs(filtered);
    
  };
 

   // Reset filter to show all blogs
   const resetFilter = () => {
    setFilteredBlogs(blogs);
  };
  const [visibleBlogs, setVisibleBlogs] = useState(12);

  const handleViewMore = () => {
    setVisibleBlogs(blogs.length);
  };

  const handleViewLess = () => {
    setVisibleBlogs(12);
  };

  const fetchBlogs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/get-all-temples`
      );
      setBlogs(response.data?.data);
      setFilteredBlogs(response.data?.data);
      console.log("h",response.data?.data)
    } catch (err) {
      setError("Failed to fetch blogs");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchBlogs();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

    // Helper function to truncate text to 145 words
    const truncateText = (text, wordLimit) => {
      const words = text.split(' ');
      if (text.length <= wordLimit) {
      return text;
      }
      return text.slice(0, wordLimit) + '...';
      };
    

  const [toggle,settoggle]=useState(false)  
  const handletoggle=()=>{
    if(toggle==true){
      settoggle(false)
    }else{
      settoggle(true)
    }
  
  }  
  return (
    <Layout title="Temples" discription="Hindu Temple" keywords="Hindu Temple">
        <section className="page-header">
            <div
              className="page-header__bg"
              // style={{ backgroundImage: `url(${bannerImage})` }}
              style={{ backgroundImage: `url(${templeBannerImage})` }}
            ></div>
            <div className="container">
              <ul className="sanatanSewaNyas-breadcrumb list-unstyled">
                <li>
                  <a href="index.html">Home</a>
                </li>
                <li>
                  <span>Temples</span>
                </li>
              </ul>
              <h2 className="page-header__title">
                {/* Temples Page */}मंदिर
                </h2>
            </div>
          </section>
    <div className="container-fluid">
      <div className="row">
      <div className="col-3">
        
       <div className="row mt-5">
        <div className="mt-3 mx-2">
          <h4 className="fw-bold ">Filters</h4>
        </div>
        <div>
        <div className=" mt-3 ">
            <div className="my-2 mx-2">
           
             <div class="form-check ">
                <input class="form-check-input " type="checkbox"  value="famous"   checked={selectedCategories.includes('famous')}   onChange={handleCheckboxChange} id="flexCheckDefault"/>
                <label class="form-check-label" for="flexCheckDefault">
               Famous
                </label>
            </div>
            {/* <div class="form-check">
                <input class="form-check-input" type="checkbox" value="reset"  checked={selectedCategories.length === 0}   onChange={(e) => {
                        if (e.target.checked) {
                          resetFilter();
                        }
                      }}id="flexCheckDefault"/>
                <label class="form-check-label" for="flexCheckDefault">
                All
                </label>
            </div> */}

          
                {/* <button className="btn btn-warning mx-1 px-5 text-white rounded-button" style={{borderRadius:"20px",backgroundColor:"#FC730A"}} onClick={() => handleFilter('famous')}>Famous</button>
                <button className="btn btn-danger  px-5 text-white" style={{borderRadius:"20px",backgroundColor:"#FC730A"}} onClick={resetFilter}>
                All Temple
              </button> */}
            </div>
           
          </div>
          <div className="d-flex justify-content-between align-items-center p-2 drop_bg">
            <div className="fw-bold">City</div><div onClick={handletoggle} >{toggle==true? <KeyboardArrowUpIcon/>:<KeyboardArrowDownIcon/>}</div>
          
          </div>
          <div>
            {toggle==true?(          
              <ul className="list-unstyled list-unstyled-custom">
              {filteredBlogs.map((item,index)=>(
                <li className="bg-lightgray p-2"  onClick={() => handlecityFilter(item.city)}>{item.city}</li>
              ))
              }
              {filteredBlogs.length==1?( <button className="btn btn-danger mt-2 px-4 text-white" style={{borderRadius:"20px"}} onClick={resetFilter}>
                Reset Filter
              </button>):(null)
              }
              
            </ul>):null}

    

              
           
          </div>
        </div>
       </div>
      </div>
      <div className="col-9">

  
          <section className="blog-one blog-one--page">
            <div className="container">
              {loading ? (
                <p>Loading...</p>
              ) : error ? (
                <p>{error}</p>
              ) : blogs.length === 0 ? (
                <p>No temples found</p>
              ) : visibleBlogs < blogs.length ? 
              (
                <div className="row gutter-y-30 ">
                  {filteredBlogs?.length > 0 &&
                    filteredBlogs?.slice(0, visibleBlogs).map((blog, index) => {
                      const truncatedDescription = truncateText(blog.shortdescription, 110);
                      
                      return (
                        <div key={index} className="col-md-6 col-lg-4 mx-0">
                          <div
                            className="blog-card wow fadeInUp"
                            data-wow-duration="1500ms"
                            data-wow-delay="00ms"
                          >
                            {/* <div className="blog-card__date">
                              {formatDate(blog.establishedDate)}
                            </div> */}
                            <div className="blog-card__image">
                              {/* <img src={section22} alt={blog.title} /> */}
                              <img src={`${process.env.REACT_APP_BASE_URL}/temples/${blog?.bannerImage?.image}`} alt={blog.title} />
                              {/* <img src={`${process.env.REACT_APP_BASE_URL}/temples/${blog?.mainImage?.image}`} alt={blog.title} /> */}
                              {/* <img src={blog.mainImage} alt={blog.title} /> */}
                            </div>
                            <div className="blog-card__content">
                              <ul className="list-unstyled blog-card__meta">
                                <li>
                                  <i className="fa fa-user-circle"></i>
                                  <a href="blog.html">{blog.city},</a>

                                  <i className="fa fa-comments"></i>
                                  {blog.state}
                                </li>
                              </ul>
                              <h3 className="blog-card__title">
                                <Link to={`/temple-detail/${blog._id}`}>
                                  {blog.title}
                                </Link>
                              </h3>
                              <span className="">
                              {formatDate(blog.establishedDate)}
                            </span>
                              <p className="blog-card__short">
                                {/* {blog.shortdescription} */}
                                { truncatedDescription}
                              </p>
                              <Link
                                to={`/temple-detail/${blog._id}`}
                                className="blog-card__link"
                              >
                                {/* Read more */}और पढ़ें...
                                <i className="icon-right-arrow">
                                  <FontAwesomeIcon icon={faArrowRightLong} />
                                </i>
                              </Link>
                            </div>
                          </div>
                          
                    
                        </div>

                        
                      );
                    })}
                    { filteredBlogs?.length>= 11 ?(<div className="text-center mt-4">
                            <button className="btn btn-dark px-4" onClick={handleViewMore}>
                            View More
                            </button>
                          </div>):(null)
                          }
                    
                          
                </div>
                  
                
                
              ):          (
                <div className="row gutter-y-30 ">
                  {filteredBlogs?.length > 0 &&
                    filteredBlogs?.map((blog, index) => {
                      const truncatedDescription = truncateText(blog.shortdescription, 110);
                      
                      return (
                        <div key={index} className="col-md-6 col-lg-4 mx-0">
                          <div
                            className="blog-card wow fadeInUp"
                            data-wow-duration="1500ms"
                            data-wow-delay="00ms"
                          >
                            {/* <div className="blog-card__date">
                              {formatDate(blog.establishedDate)}
                            </div> */}
                            <div className="blog-card__image">
                              {/* <img src={section22} alt={blog.title} /> */}
                              <img src={`${process.env.REACT_APP_BASE_URL}/temples/${blog?.bannerImage?.image}`} alt={blog.title} />
                              {/* <img src={blog.mainImage} alt={blog.title} /> */}
                            </div>
                            <div className="blog-card__content">
                              <ul className="list-unstyled blog-card__meta">
                                <li>
                                  <i className="fa fa-user-circle"></i>
                                  <a href="blog.html">{blog.city},</a>

                                  <i className="fa fa-comments"></i>
                                  {blog.state}
                                </li>
                              </ul>
                              <h3 className="blog-card__title">
                                <Link to={`/temple-detail/${blog._id}`}>
                                  {blog.title}
                                </Link>
                              </h3>
                              <span className="">
                              {formatDate(blog.establishedDate)}
                            </span>
                              <p className="blog-card__short">
                                {/* {blog.shortdescription} */}
                                { truncatedDescription}
                              </p>
                              <Link
                                to={`/temple-detail/${blog._id}`}
                                className="blog-card__link"
                              >
                                {/* Read more */}और पढ़ें...
                                <i className="icon-right-arrow">
                                  <FontAwesomeIcon icon={faArrowRightLong} />
                                </i>
                              </Link>
                            </div>
                          </div>

                        
                        </div>
                      );
                    })}
                        <div className="text-center mt-4">
                            <button className="btn btn-dark px-4" onClick={handleViewLess}>
                            View Less
                            </button>
                          </div>
                </div>
                  
                
                
              )}
            </div>
          </section>
      </div>
      </div>
    </div>  

    </Layout>
  );
}

export default Temples;
