import React,{useState,useEffect} from 'react'
import axios from 'axios';
import { useParams } from "react-router-dom";
import Layout from "../../Components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import pressrelese from "../../Assets/Images/homeBanner/press-relese.png";
import moment from 'moment';
function PressReleaseDetail() {
    const [pressData, setPressData] = React.useState([]);
    const { id } = useParams();
    const getEvent = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/get-pressreliese/${id}`
        );
      console.log("data",response.data?.data)
    setPressData(response.data?.data);

      } catch (error) {
        console.log(error);
      }
    };
    useEffect(() => {
      getEvent();
      console.log(pressData,"pressData");
    }, []);


    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric" };
        return new Date(dateString).toLocaleDateString(undefined, options);
      };
    

      const truncateText = (text, letterLimit) => {
        if (text.length <= letterLimit) return text;
        return text.slice(0, letterLimit) + " ...";
      };
   console.log(pressData,"pressData");
  return (
    <Layout
    title="Event-Details"
    discription={"Events of  Sanatan sewanyas"}
    keywords={"Events Sanatan Sewanyas"}
  >
    <section className="page-header">
      <div className="page-header__bg"></div>
      <div className="container">
        <ul className="sanatanSewaNyas-breadcrumb list-unstyled">
          <li>
            <a href="index.html">Home</a>
          </li>
          <li>
            <span>Events</span>
          </li>
        </ul>
        {pressData && pressData?.title && (<h2 className="page-header__title">{pressData && pressData?.title}</h2>)}

      </div>
    </section>
    <section className="blog-details">
      <div className="container">
        <div key={pressData?._id} className="row gutter-y-60">
          <div className="col-lg-8">
            <div className="blog-details__content">
              <div className="blog-details__image">
                <img
                  style={{height:"60vh"}}
                   src={pressrelese}
                  alt={pressData?.title}
                />
                 
              </div>
              <div className="d-flex justify-content-between">
              
              
              </div>
              <h3 className="blog-details__title" style={{fontWeight:"600"}}>{pressData?.title}</h3>
              <p> {moment(pressData.createdAt).format('MMMM DD, YYYY')}</p>
              
              < div className="blog-details__text" dangerouslySetInnerHTML={{ __html: pressData.content}}/>

            </div>
          </div>
          <div className="col-lg-4">
            <div className="row ">
              <div className='col-12'>
              <div
                      className="blog-card3 wow fadeInUp "
                      data-wow-duration="1500ms"
                      data-wow-delay="00ms"
                    >
                      {/* <div className="blog-card__date">
                        {formatDate(event.startDate)}
                      </div> */}
                      <div className="blog-card__image2">
                       
                        <img 
                        
                       src={pressrelese}
                     
                      />
                        
                      </div>
                      <div className="blog-card__content">
                          <span className="">
                              {/* {formatDate(event.startDate)} */}
                            </span>
                        <ul className="list-unstyled blog-card__meta mt-2">
                          <li>
                            <i className="fa fa-user-circle"></i>
                            <a href="blog.html">
                         
                            </a>
                          </li>
                       
                        </ul>
                        <h3 className="blog-card__title">
                          <a href="blog-details.html">
                          live session  {/* {press.title} */}
                            </a>
                        </h3>
                        <span className="">
                           
                         </span>
                     
                        <p className="mt-2">
                       hello this is live

                           
                        </p>
             
                      </div>
                </div>
              </div>
           
            </div>
          </div>
        </div>
   
      </div>
    </section>


  </Layout>
  )
}

export default PressReleaseDetail